import Phaser from "phaser";
import { createCard } from "../gameComponents/card";

/**
 * Clase para crear el Primer Nivel del juego
 * @author Mehdi Lahbis Mhamdi
 * @class
 * @property {Array} cardNames - Array con los nombres de las cartas del nivel.
 * @property {Array} cards - Array con los objetos carta creados.
 * @property {Object} cardOpened - Variable para detectar la carta elegida en cada momento.
 * @property {boolean} canMove - Para controlar la interacción del usuario/a.
 * @property {number} combo - Número de aciertos consecutivos para comprobar si hay combo. 
 * @property {number} score - Puntuación. 
 * @property {number} lives - Número de vidas/intentos. 
 * @property {String} scoreText - Texto de la puntuación. 
 * @property {boolean} firstAttempt - Variable para detectar el primer intento del usuario/a.
 * @property {JSONObject} gridConfiguration - Configuración del posicionamiento de las cartas.
 */
export class FirstLevel extends Phaser.Scene {
    cardNames = ["card-0", "card-1", "card-2", "card-3", "card-4", "card-5"];

    cards = [];

    cardOpened = undefined;

    canMove = false;

    combo = 0;

    score = 0;

    lives = 0;

    scoreText = '';

    passed = false;

    numLevel = 1;

    firstAttempt = false;

    gridConfiguration = {
        x: 720,
        y: 240,
        paddingX: 120,
        paddingY: 120
    };

    /**
    * Constructor Nivel 1
    */
    constructor() {
        super({
            key: 'firstLevel'
        });
    }

    /**
     * Método para asignar valores iniciales a las propiedades de la escena.
     * <br>
     * Se ejecuta antes del método create.
     */
    init() {
        // espera al iniciar de 500 milisegundos
        this.cameras.main.fadeIn(500);
        this.lives = 6;
        this.firstAttempt = true;
    }

    /**
    * Método para crear los elementos de la escena
    */
    create() {
        // imagen de fondo
        const bg = this.add.image(1000, 510, 'bg');

        // título y su animación (de arriba a abajo)
        const title = this.add.text(this.scale.width / 2 - (182 / 2), -1000, "Memorik",
            { align: 'center', fontSize: 45, fontFamily: "Quicksand" });
        this.add.tween({
            targets: title,
            ease: Phaser.Math.Easing.Expo.InOut,
            duration: 1000,
            delay: 500,
            y: 40
        });

        // icono puzzle y su animación (de derecha a izquierda)
        const puzzle = this.add.image(10000, 70, 'icon');
        puzzle.setScale(0.02);

        this.add.tween({
            targets: puzzle,
            ease: Phaser.Math.Easing.Expo.InOut,
            duration: 1000,
            delay: 500,
            x: this.scale.width - 100
        });

        // fondo blanco para el nombre de la tanda
        this.seriesBg1 = this.add.image(90, 75, 'dropBg').setTintFill(0xFFFFFF);
        this.seriesBg2 = this.add.image(140, 75, 'dropBg').setTintFill(0xFFFFFF);
        this.seriesBg3 = this.add.image(190, 75, 'dropBg').setTintFill(0xFFFFFF);
        // nombre de tanda y su animación (de izquierda a derecha)
        const series = this.add.text(-200, 50, 'NIVEL 1',
            { align: 'center', fontStyle: "bold", fontSize: 45, fontFamily: "Quicksand", color: '#F39C12' }).setDepth(1);

        this.add.tween({
            targets: series,
            ease: Phaser.Math.Easing.Expo.InOut,
            duration: 1000,
            delay: 500,
            x: 55
        });

        this.seriesContainer = this.add.container(0, 0);

        this.seriesContainer.add(this.seriesBg1);
        this.seriesContainer.add(this.seriesBg2);
        this.seriesContainer.add(this.seriesBg3);
        this.seriesContainer.add(series);

        // fondo blanco para la puntuación
        this.add.image(350, 75, 'dropBg').setTintFill(0xFFFFFF);
        this.add.image(400, 75, 'dropBg').setTintFill(0xFFFFFF);
        this.add.image(450, 75, 'dropBg').setTintFill(0xFFFFFF);
        this.add.image(500, 75, 'dropBg').setTintFill(0xFFFFFF);
        this.add.image(550, 75, 'dropBg').setTintFill(0xFFFFFF);

        // puntuación y su animación
        this.scoreText = this.add.text(-500, 60,
            "PUNTUACIÓN|  " + this.score,
            { align: "top", fontStyle: "bold", fontSize: 30, fontFamily: 'Quicksand', color: "#F39C12" }
        ).setDepth(1);

        this.game.events.emit("POINTS", this.score);
        this.game.events.emit("PASSED LEVEL", this.passed);
        this.game.events.emit("PASSED NUM LEVEL", this.numLevel);

        this.add.tween({
            targets: this.scoreText,
            ease: Phaser.Math.Easing.Expo.InOut,
            duration: 1000,
            delay: 500,
            x: 305
        });

        // llamada al método que inicia el juego
        this.startGame();
    }

    /**
    * Método para crear las cartas del nivel.
    * @returns {newCard} 
    */
    createGridCards() {
        // Se baraja el array que contiene las parejas de cartas
        const gridCardNames = Phaser.Utils.Array.Shuffle([...this.cardNames, ...this.cardNames]);

        // se recorre el array y para cada, elemento se crea una nueva carta asignándole una posición
        return gridCardNames.map((name, index) => {
            const newCard = createCard({
                scene: this,
                x: this.gridConfiguration.x + (98 + this.gridConfiguration.paddingX) * (index % 4),
                y: 5000,
                frontTexture: name,
                cardName: name
            });

            // animación (de abajo hacia arriba) de cada carta dentro del array 
            this.add.tween({
                targets: newCard.gameObject,
                duration: 800,
                delay: 1000 + index * 100,
                scale: 1,
                y: this.gridConfiguration.y + (150 + this.gridConfiguration.paddingY) * Math.floor(index / 4)
            })
            return newCard;
        });
    }

    /**
    * Método para crear tantas gotas (con su fondo) como intentos queremos que tenga el usuario.
    * <br>
    * lives = 6 
    * @returns {waterDrop}
    */
    createWaterDrops() {
        return Array.from(new Array(this.lives)).map((el, index) => {
            const waterDrop = this.add.image(915 + 50 * index, 10000, "drop")
                .setScale(0.5).setDepth(1);

            this.add.image(915 + 50 * index, this.scale.height - 50, 'dropBg').setTintFill(0xFFFFFF).setScale(0.8);

            // animación de abajo hacia arriba de las gotas
            this.add.tween({
                targets: [waterDrop],
                ease: Phaser.Math.Easing.Expo.InOut,
                duration: 1000,
                delay: 1000 + index * 200,
                y: this.scale.height - 50
            });

            return waterDrop;
        });
    }

    /**
    * Método que se ejecuta cuando el usuario consiga hacer un 'combo'
    * <br>
    */
    createCombo() {
        // creamos el texto
        const comboText = this.add.text(this.scale.width / 2 + 40, this.scale.height / 2, '¡COMBO!',
            { align: 'center', fontStyle: "bold", fontSize: 45, fontFamily: "Quicksand", color: '#94C11E' }).setDepth(1).setScale(0);

        // creamos el fondo del texto
        const comboBg = this.add.image(this.scale.width / 2 + 50, this.scale.height / 2, 'textBg').setTintFill(0xFFFFFF).setScale(0);

        // animaciones
        this.add.tween({
            targets: comboText,
            scale: 1,
            duration: 1200,
            x: this.scale.width / 2 - 20,
            y: this.scale.height / 2,
            ease: Phaser.Math.Easing.In,
            onUpdate: () => {
                this.canMove = false;
            },
            onComplete: () => {
                setTimeout(() => {
                    this.add.tween({
                        targets: [comboText, comboBg],
                        scale: 0,
                        x: this.scale.width / 2 + 50,
                        y: this.scale.height / 2 + 10,
                        duration: 1200,
                        ease: Phaser.Math.Easing.Out
                    })
                }, 2000);
                this.canMove = true;
            }
        });
        this.add.tween({
            targets: comboBg,
            x: this.scale.width / 2 + 70,
            y: this.scale.height / 2 + 60,
            scale: 2,
            duration: 1200,
            ease: Phaser.Math.Easing.In
        })
    }

    /**
    * Método para volver a intentar el nivel.
    * <br>
    */
    restartGame() {
        this.cardOpened = undefined;
        this.score = 0;
        this.combo = 0;
        //this.cameras.main.fadeOut(200 * this.cards.length);
        this.cards.reverse().map((card, index) => {
            this.add.tween({
                targets: card.gameObject,
                duration: 500,
                y: 1000,
                delay: index * 100,
                onComplete: () => {
                    card.gameObject.destroy();
                }
            })
        });

        this.time.addEvent({
            delay: 200 * this.cards.length,
            callback: () => {
                this.cards = [];
                this.canMove = false;
                this.scene.restart();
            }
        })
    }

    /**
    * Método para actualizar el texto de la puntuación.
    * <br>
    */
    update() {
        this.scoreText.setText('PUNTUACIÓN |  ' + this.score);
        this.game.score = this.score;
        this.game.passed = this.passed;
        this.game.numLevel = this.numLevel;
    }

    /**
    * Método que inicia el juego.
    * <br>
    */
    startGame() {
        // Se crean las gotas
        const waterDrops = this.createWaterDrops();

        // Se crean las cartas 
        this.cards = this.createGridCards();

        // No se pueden hacer movimientos hasta que se posicionen todas las cartas del nivel
        this.time.addEvent({
            delay: 200 * this.cards.length,
            callback: () => {
                this.canMove = true;
            }
        });

        // Con este código indicamos que cuando el cursor esté encima de una carta se ponga en forma 
        // 'pointer' para que el usuario vea que es un elemento interactivo
        this.cards.forEach(card => {
            card.gameObject.on(Phaser.Input.Events.POINTER_OVER, () => {
                this.input.setDefaultCursor("pointer");
            });
            card.gameObject.on(Phaser.Input.Events.POINTER_OUT, () => {
                this.input.setDefaultCursor("default");
            })
        });

        // Lógica del juego
        this.cards.forEach(card => {
            card.gameObject.on(Phaser.Input.Events.POINTER_DOWN, (pointer) => {
                // Condición para manejar el clic solamente si está dentro de un área específica 
                // de la imagen
                if (pointer.x > card.gameObject.x - 50 && pointer.x < card.gameObject.x + 50
                    && pointer.y > card.gameObject.y - 75 && pointer.y < card.gameObject.y + 75) {
                    if (this.canMove && this.cards.length) {
                        if (card) {
                            this.canMove = false;
                            // Detectar si hay una carta seleccionada previamente
                            if (this.cardOpened !== undefined) {
                                // Si se pulsa la misma carta no se hace nada
                                if (this.cardOpened.gameObject.x === card.gameObject.x && this.cardOpened.gameObject.y === card.gameObject.y) {
                                    this.canMove = true;
                                    return false;
                                }
                                // se le da la vuelta a la carta pulsada
                                card.flip(() => {
                                    
                                    this.canMove = false;
                                    // si las 2 cartas son iguales
                                    if (this.cardOpened?.cardName === card.cardName) {
                                        // ------- Acierto -------
                                        const score = this.add.text(530, 110, '+1',
                                            { align: 'center', fontStyle: "bold", fontSize: 22, fontFamily: "Quicksand", color: '#fff' });

                                        // animación de la puntuación obtenida
                                        this.add.tween({
                                            targets: score,
                                            x: 530,
                                            y: 60,
                                            duration: 1500,
                                            onComplete: () => {
                                                this.score += 1;
                                            }
                                        })

                                        // Eliminar las 2 cartas del historial, despues de 1100 milisegundos
                                        setTimeout(() => {
                                            this.cardOpened.destroy();
                                            card.destroy();
                                            this.cardOpened = undefined;
                                            this.canMove = true;
                                        }, 1100);
                                        // incrementar el contador de combo
                                        this.combo++;
                                        // eliminar la carta destruida del array
                                        this.cards = this.cards.filter(cardLocal => cardLocal.cardName !== card.cardName);
                                        this.canMove = false;

                                    } else {
                                        // ------- Fallo -------
                                        // si es primer intento, pasamos la variable a false y no hacemos nada más
                                        if (this.firstAttempt) {
                                            this.firstAttempt = false;
                                        } else {
                                            // animación de poner en un color más tenue la última gota de la lista
                                            const lastwaterDrop = waterDrops[waterDrops.length - 1];
                                            this.add.tween({
                                                targets: lastwaterDrop,
                                                duration: 1000,
                                                alpha: 0.2,
                                                onComplete: () => {
                                                    waterDrops.pop();
                                                }
                                            });
                                            // decrementar las oportunidades en 1 unidad
                                            this.lives -= 1;
                                        }
                                        // se vuelven a dar la vuelta las 2 cartas
                                        setTimeout(() => {
                                            this.tweens.add({
                                                targets: [card.gameObject, this.cardOpened],
                                                scaleX: 0, 
                                                ease: Phaser.Math.Easing.Expo.InOut,
                                                duration: 200, 
                                                yoyo: true
                                            })
                                            card.flip();
                                            this.cardOpened?.flip(() => {
                                                this.cardOpened = undefined;
                                                this.canMove = true;
                                            });
                                        }, 1000);
                                        this.canMove = false;
                                        this.combo = 0;
                                    }

                                    // en caso de agotar las oportunidades, llamada al método 'gameOver()'
                                    if (this.lives === 0) {
                                        this.gameOver();
                                        this.canMove = false;
                                    }

                                    // si no quedan cartas, significa que se ha completado el nivel
                                    // por lo tanto, se llama al método 'nextLevel()'
                                    if (this.cards.length === 0) {
                                        this.nextLevel();
                                        this.numLevel = 2;
                                        this.passed = true;
                                        this.canMove = false;
                                    }

                                    // comprobamos si se ha logrado un 'combo'
                                    if (this.combo >= 2) {
                                        // texto de la puntuación obtenida
                                        const score = this.add.text(530, 130, '+2',
                                            { align: 'center', fontStyle: "bold", fontSize: 22, fontFamily: "Quicksand", color: '#fff' });
                                        // si no se ha completado el nivel aún, se llama al método que generar
                                        // el texto con su animación
                                        if (this.cards.length > 1) {
                                            this.createCombo();
                                        }
                                        this.add.tween({
                                            targets: score,
                                            x: 530,
                                            y: 60,
                                            duration: 1500,
                                            onComplete: () => {
                                                this.score += 2;
                                            }
                                        });
                                    }
                                });
                            } else if (this.cardOpened === undefined && this.lives > 0 && this.cards.length > 0) {
                                // si no hay ninguna carta seleccionada previamente, se guarda la carta pulsada
                                // en la variable de carta seleccionada
                                card.flip(() => {
                                    this.canMove = true;
                                });
                                this.cardOpened = card;
                            }
                        }
                    }
                }
            });
        });
    }

    /**
    * Método para avanzar de nivel.
    * <br>
    */
    nextLevel() {
        // Icono check al centro de la pantalla a modo de zoom
        const ok = this.add.image(this.scale.width / 2 + 50, this.scale.height / 2, 'ok').setScale(0);
        this.add.tween({
            targets: ok,
            duration: 1200,
            scale: 0.4,
            x: this.scale.width / 2 + 50
        });

        // Creamos el contenedor con los elementos del cuadro de 'siguiente nivel'
        const winnerContainer = this.add.container(0, 0);
        const nextLevel = this.add.image(1665, 850, 'next').setScale(2.8);

        const nextBg1 = this.add.image(1790, 970, 'dropBg').setTintFill(0xFFFFFF);
        const nextBg2 = this.add.image(1840, 970, 'dropBg').setTintFill(0xFFFFFF);
        const nextBg3 = this.add.image(1890, 970, 'dropBg').setTintFill(0xFFFFFF);

        const nextBtn = this.add.image(1845, 870, 'nextBtn').setScale(0.1).setInteractive({ cursor: 'pointer' });

        const nextLevelText = this.add.text(1755, 960, 'Siguiente nivel',
            { align: 'center', fontStyle: "bold", fontSize: 22, fontFamily: "Quicksand", color: '#F39C12' })
            .setDepth(1)
            .setInteractive({ cursor: 'pointer' })

        const winnerText = this.add.text(1840, 780, "¡Muy bien!",
            { align: "center", fontStyle: "bold", fontSize: 40, fontFamily: "Quicksand", color: "#fff" }
        ).setOrigin(.5)
            .setDepth(3);

        winnerContainer.add(nextLevel);
        winnerContainer.add(nextBtn);

        winnerContainer.add(winnerText);
        winnerContainer.add(nextBg1);
        winnerContainer.add(nextBg2);
        winnerContainer.add(nextBg3);
        winnerContainer.add(nextLevelText);

        winnerContainer.y = 1000;
        winnerContainer.x -= 50

        // animación del cuadro de 'siguiente nivel' a modo de barrido de abajo a arriba
        this.add.tween({
            targets: winnerContainer,
            duration: 1500,
            ease: Phaser.Math.Easing.Out,
            y: 0,
        });

        // eventos clic en el botón 'Play' y en el texto
        nextLevelText.on(Phaser.Input.Events.POINTER_DOWN, () => {
            // el icono de pasar de nivel desaparece a modo de zoom pero al inversa
            this.add.tween({
                targets: ok,
                duration: 1200,
                scale: 0
            });
            // el nombre del nivel desaparece de la pantalla por la parte izquierda
            this.add.tween({
                targets: this.seriesContainer,
                duration: 1200,
                x: -500
            });
            // cuadro de 'siguiente nivel' desaparece de arriba hacia abajo e iniciamos el nivel siguiente
            this.add.tween({
                targets: winnerContainer,
                ease: Phaser.Math.Easing.InOut,
                duration: 1500,
                y: 1000,
                onComplete: () => {
                    this.scene.start('secondLevel', { score: this.score });
                }
            });
        });

        nextBtn.on(Phaser.Input.Events.POINTER_DOWN, () => {
            // el icono de pasar de nivel desaparece a modo de zoom pero al inversa
            this.add.tween({
                targets: ok,
                duration: 1200,
                scale: 0
            });
            // el nombre del nivel desaparece de la pantalla por la parte izquierda
            this.add.tween({
                targets: this.seriesContainer,
                duration: 1200,
                x: -500
            });
            // cuadro de 'siguiente nivel' desaparece de arriba hacia abajo e iniciamos el nivel siguiente
            this.add.tween({
                targets: winnerContainer,
                ease: Phaser.Math.Easing.InOut,
                duration: 1500,
                y: 1000,
                onComplete: () => {
                    this.scene.start('secondLevel', { score: this.score });
                }
            });
        });
    }

    /**
    * Método que se ejecuta al perder todas las vidas.
    * <br>
    */
    gameOver() {
        // sigue intentándolo (imagen)
        const comboBg = this.add.image(this.scale.width / 2 + 50, this.scale.height / 2, 'gameOver').setScale(0).setDepth(1);
        // animación
        this.add.tween({
            targets: comboBg,
            scale: 1.2,
            duration: 1200,
            x: this.scale.width / 2 + 50,
            y: this.scale.height / 2,
            ease: Phaser.Math.Easing.In,
            onUpdate: () => {
                this.canMove = false;
            },
            onComplete: () => {
                setTimeout(() => {
                    this.add.tween({
                        targets: comboBg,
                        scale: 0,
                        x: this.scale.width / 2 + 50,
                        y: this.scale.height / 2 + 10,
                        duration: 1200,
                        ease: Phaser.Math.Easing.Out
                    })
                }, 3000);
                this.canMove = true;
            }
        });

        // cuadro de 'volver a intentar'
        const tryAgainContainer = this.add.container(0, 0);
        const restartBtn = this.add.image(215, 840, 'restartBtn').setScale(0.1).setInteractive({ cursor: 'pointer' }).setDepth(3);
        const restartLevel = this.add.image(40, 850, 'levelEnd').setAlpha(1).setScale(2.8).setDepth(0);
        const restartBg1 = this.add.image(170, 940, 'dropBg').setTintFill(0xFFFFFF);
        const restartBg2 = this.add.image(220, 940, 'dropBg').setTintFill(0xFFFFFF);
        const restartBg3 = this.add.image(270, 940, 'dropBg').setTintFill(0xFFFFFF);

        const restartLevelText = this.add.text(125, 930, 'Volver a empezar',
            { align: 'center', fontStyle: "bold", fontSize: 22, fontFamily: "Quicksand", color: '#F39C12' })
            .setDepth(1)
            .setInteractive({ cursor: 'pointer' });

        tryAgainContainer.add(restartLevel);
        tryAgainContainer.add(restartBtn);

        tryAgainContainer.add(restartBg1);
        tryAgainContainer.add(restartBg2);
        tryAgainContainer.add(restartBg3);
        tryAgainContainer.add(restartLevelText);

        tryAgainContainer.y = 1000;

        // animación de cuadro de 'volver a intentar'
        this.add.tween({
            targets: tryAgainContainer,
            duration: 2000,
            ease: Phaser.Math.Easing.Out,
            y: 0,
        });

        // eventos clic en texto y botón
        restartLevelText.on(Phaser.Input.Events.POINTER_DOWN, () => {
            this.add.tween({
                targets: tryAgainContainer,
                ease: Phaser.Math.Easing.InOut,
                y: 1000,
                onComplete: () => {
                    this.restartGame();
                }
            })
        });

        restartBtn.on(Phaser.Input.Events.POINTER_DOWN, () => {
            this.add.tween({
                targets: tryAgainContainer,
                ease: Phaser.Math.Easing.InOut,
                y: 1000,
                onComplete: () => {
                    this.restartGame();
                }
            })
        });
    }
}