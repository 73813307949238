import React, { Component } from "react";

import { Modal, Button, Space, Layout } from "antd";
import { message } from "antd";
import { inject, observer } from "mobx-react";
import { reaction } from "mobx";

import PlaygroundModal from "../components/PlaygroundModal.js";
import LevelsModal from "../components/LevelsModal.js";

import playIcon from '../assets/images/buttons/minijuegos.png';
import rankingIcon from '../assets/images/buttons/ranking.png';
import showChange from '../assets/images/buttons/inicio-fin.png';

import configIcon from '../assets/images/buttons/IconoAjustes-PG.png';
// import configSoundIcon from '../assets/images/buttons/IconoSonido-PG.png';
import configPersonIcon from '../assets/images/buttons/IconoPersona-PG.png';
import configExitIcon from '../assets/images/buttons/IconoSalir-PG.png';
import configLegalIcon from '../assets/images/buttons/IconoAviso_Legal-PG.png';
import shopIcon from '../assets/images/buttons/tienda.png';

import GlobalFonts from "../assets/fonts/fonts.js";

import Avatar from '../components/Avatar';

import './Main.scss';
import RankingModal from "../components/RankingModal.js";
import AvatarModal from "../components/AvatarModal.js";
import ManualModal from "../components/ManualModal.js";
import EditorPlayerModal from "../components/EditorPlayerModal.js";
import ThreatComponent from "../components/ThreatComponent.js";
import ThreatPlayComponent from "../components/ThreatPlayComponent.js";
import EndGameModal from "../components/EndGameModal.js";

const { Content } = Layout;

class Main extends React.Component {

    //*********************************************************
    constructor(props) {
        super(props);
        this.modalRef = React.createRef();
        this.user = null;

        this.state = {
            collapsed: "1",
            title: '',
            icon: null,
            show: false,
            showDraggableModal: false,
            showAvatarModal: false,
            showManualModal: false,
            showEditorPlayerModal: false,
            close: true,
            imagesConfigVisible: false,
            showLevelsModal: false,
            showRankingModal: false,
            showEndGameModal: false,
            background: "",
            opacityOfActualBackground: "",
            opacityOfNextBackground: "0",
            first: false,
            alt: '',
            isHovered: false,
            previous: false
        }
        this.reactions = [];
    }

    //*********************************************************
    //********************** EFECTO BACKGROUND ***********************
    // componentDidUpdate() {
    //     this.reactions.push(
    //         reaction(
    //             () => this.props.generalStore.processing.includes(Actions.CHANGE_STEP),
    //             () => {
    //                 const isChangingBackground = this.props.generalStore.processing.includes(Actions.CHANGE_STEP);
    //                 var opacity = 1;
    //                 var inverse = 0;

    //                 if (isChangingBackground) {
    //                     setInterval(() => {
    //                         if (opacity > 0) {
    //                             opacity -= 0.2;
    //                             this.setState({ opacityOfActualBackground: opacity.toString() })
    //                         }
    //                     }, 100);  

    //                     setTimeout(() => {
    //                         setInterval(() => {
    //                             if (inverse < 1) {
    //                                 inverse += 0.2;
    //                                 this.setState({ opacityOfNextBackground: inverse.toString() })
    //                             }
    //                         }, 200); 
    //                         this.setState({ showLevelsModal: false });
    //                         setTimeout(() => {
    //                             window.location.reload();
    //                         }, 1500);        
    //                         //window.location.reload();  
    //                     }, 700);                             
    //                 }
    //             }
    //         )           
    //     )
    // }

    //*********************************************************
    componentDidMount() {
        this.handleLoadTeamsData();

        this.reactions.push(
            reaction(
                () => this.props.usersStore.loggedIn,
                () => {
                    if (!this.props.usersStore.loggedIn) {
                        this.props.history.push('/');
                        this.setState({ userLogged: this.props.usersStore.userLogged })
                    }
                }
            )
        );

        window.onbeforeunload = this.handleBeforeUnload;
    }

    //*********************************************************
    componentWillUnmount() {
        window.onbeforeunload = null;
    }

    //*********************************************************
    handleLoadTeamsData = () => {
        if (this.props.teamsStore != undefined) {
            this.props.teamsStore.doActionGetStepsList();
            this.props.teamsStore.doActionGetTeamsList();
        }
    }

    //*********************************************************
    handleLogoutConfirm = () => {
        Modal.confirm({
            title: '¿Estás seguro que deseas cerrar sesión?',
            okText: 'Sí',
            cancelText: 'No',
            onOk: () => {
                this.handleLogout();
            },
            onCancel() {
                console.log('Cancelado');
            },
        });
    }

    //*********************************************************
    handleBeforeUnload = (event) => {
        if (!this.props.intentionalReload) {
            //event.preventDefault();
            event.returnValue = '';
        }
    }

    //*********************************************************
    handleUnload = () => {
        this.props.usersStore.doActionLogout();
    };

    //*********************************************************
    toggleConfigImages = () => {
        this.setState(prevState => ({
            imagesConfigVisible: !prevState.imagesConfigVisible
        }));
    }

    //*********************************************************
    handleLogout() {
        this.props.usersStore.doActionLogout();
    }

    //*********************************************************
    handleShow = () => {
        this.setState({ showManualModal: true });
    }

    //*********************************************************
    handleEditPlayer = () => {
        this.setState({ showEditorPlayerModal: true });
    }

    //*********************************************************
    render() {
        const userLogged = this.props.usersStore.userLogged == undefined ? undefined : this.props.usersStore.userLogged;
        const organization = userLogged.organization.id;
        const teamsList = this.props.teamsStore.teams == undefined ? [] : this.props.teamsStore.teams.list;
        const stepsList = this.props.teamsStore.steps == undefined ? [] : this.props.teamsStore.steps;

        const userLoggedTeam = teamsList?.find(item => {
            if (userLogged.role.name == "MANAGER") {
                return item.id == window.location.href.split('?')[1];
            } else {
                return item.id == userLogged.team.id
            }
        });

        const nextStep = stepsList.find(item => item.order == userLoggedTeam?.step.order + 1);
        const previousStep = stepsList.find(item => item.order == userLoggedTeam?.step.order - 1);
        const lastStep = stepsList.find((item, index) => index == stepsList.length - 1);

        return (
            <>
                <GlobalFonts />
                <Layout className="teams-panel-layout">
                    <Content>
                        <img className="background" src={require(`../assets/background gif/${userLoggedTeam == undefined ? "1.gif" : (this.state.first ? "1.gif" : (this.state.previous ? previousStep.background : userLoggedTeam.step.background))}`)} style={{ opacity: this.state.opacityOfActualBackground }} />
                        <img className="background" src={require(`../assets/background gif/${nextStep == undefined ? "1.gif" : nextStep.background}`)} style={{ opacity: this.state.opacityOfNextBackground }} />
                        <img className="background" src={require(`../assets/background gif/${previousStep == undefined ? "1.gif" : previousStep.background}`)} style={{ opacity: this.state.opacityOfNextBackground }} />
                        <Space className="iconsHorizontal">
                            <div className="button-config">
                                <Button className="button-config-with-image" onClick={this.toggleConfigImages}>
                                    <img src={configIcon} id="buttomConfig" className="buttonIcons" alt="Configuration" />
                                    {this.state.imagesConfigVisible && (
                                        <Space direction="vertical" className="iconsVertical" >
                                            {userLogged.role.id != 1 && <div className='image-container' onClick={this.handleEditPlayer} onMouseEnter={() => this.setState({ alt: "Editar jujador", isHovered: true })}
                                                onMouseLeave={() => this.setState({ isHovered: false })}>
                                                <img src={configPersonIcon} className="buttonIconsConfig" />
                                                {this.state.isHovered && <div className="alt-text">{this.state.alt}</div>}
                                            </div>}
                                            <div className='image-container' onClick={this.handleShow} onMouseEnter={() => this.setState({ alt: "Aviso legal", isHovered: true })}
                                                onMouseLeave={() => this.setState({ isHovered: false })}>
                                                <img src={configLegalIcon} className="buttonIconsConfig" />
                                                {this.state.isHovered && <div className="alt-text">{this.state.alt}</div>}
                                            </div>
                                            <div className='image-container' onClick={this.handleLogoutConfirm} onMouseEnter={() => this.setState({ alt: "Salir", isHovered: true })}
                                                onMouseLeave={() => this.setState({ isHovered: false })}>
                                                <img src={configExitIcon} className="buttonIconsConfig" />
                                                {this.state.isHovered && <div className="alt-text">{this.state.alt}</div>}
                                            </div>
                                        </Space>
                                    )}
                                </Button>
                            </div>
                            {organization != 1 && (
                                <Button className="button-score-with-image" onClick={() => this.setState({ showRankingModal: true, imagesConfigVisible: false, title: 'Ranking', icon: rankingIcon })}>
                                    <img src={rankingIcon} className="buttonIcons" alt="Ranking" />
                                </Button>)}

                            {organization != 1 && (
                                <Button className="button-play-with-image" onClick={() => this.setState({ show: true, imagesConfigVisible: false, title: 'Minijuegos', icon: playIcon })}>
                                    <img src={playIcon} className="buttonIcons" alt="Play" />
                                </Button>)}

                            {organization == 1 && (
                                <Button className="hidden-bottom" onClick={() => this.setState({ showRankingModal: true, imagesConfigVisible: false, title: 'Ranking', icon: rankingIcon })}>
                                    <img src={rankingIcon} className="buttonIcons" alt="Ranking" />
                                </Button>)}

                            {organization == 1 && (
                                <Button className="button-play-with-image-individual" onClick={() => this.setState({ show: true, imagesConfigVisible: false, title: 'Minijuegos', icon: playIcon })}>
                                    <img src={playIcon} className="buttonIcons" alt="Play" />
                                </Button>)}

                            <Button className="button-avatar-with-image" onClick={() => userLogged.role.name != "TEAMPLAYER" ? this.setState({ showAvatarModal: true, imagesConfigVisible: false, title: 'Avatar' }) : message.warning("Solamente el profesor puede cambiar el avatar.")}>
                                {userLogged.role.name == ("PLAYER") &&
                                    <Avatar team={userLoggedTeam}
                                        nameTeam={"Equipo de " + userLogged.name.toUpperCase()}
                                        points={userLoggedTeam?.score}
                                        userName={userLogged.username.split('#')[0]}
                                        levelTeam={userLoggedTeam?.step.level.num}
                                    />
                                }

                                {userLogged.role.name == ("TEAMPLAYER") &&
                                    <Avatar team={userLoggedTeam}
                                        nameTeam={userLogged.team?.name.toUpperCase()}
                                        points={userLoggedTeam?.score}
                                        userName={userLogged.username.split('#')[0]}
                                        levelTeam={userLoggedTeam?.step.level.num}
                                    />
                                }

                                {userLogged.role.name == "MANAGER" &&
                                    <Avatar team={userLoggedTeam}
                                        nameTeam={userLoggedTeam?.name.toUpperCase()}
                                        points={userLoggedTeam?.score}
                                        userName={userLogged.name.split('#')[0]}
                                        levelTeam={userLoggedTeam?.step.level.num}
                                    />
                                }
                            </Button>
                        </Space>

                        {
                            <Space className="iconsHorizontal">
                                <Button className="button-shop-with-image" onClick={() => userLoggedTeam?.step.id != lastStep?.id ? this.setState({ showLevelsModal: true, imagesConfigVisible: false, title: 'Tienda', icon: shopIcon }) : this.setState({ showEndGameModal: true })}>
                                    <img src={shopIcon} className="buttonIcons" alt="Shop" />
                                </Button>
                            </Space>
                        }
                        {(userLoggedTeam?.step.order != 24 && userLoggedTeam?.step.order != 0) && userLoggedTeam?.step.background != previousStep?.background &&

                            // <Button className="button-show-previous-step" onClick={() => this.setState({ previous: !this.state.previous })}>
                            //     <img src={showChange} className={`"buttonShow-"${this.state.previous ? "rightRotate" : "leftRotate"}`} alt="Previous" />
                            // </Button>
                            <Button className="button-show-previous-step" onClick={() => this.setState({ previous: !this.state.previous })}>
                                <img src={showChange} className={`buttonShow ${this.state.previous ? "" : "rotated"}`} alt="Previous" />
                            </Button>
                            // <div className="background-show-previous-step" onClick={() => this.setState({ previous: !this.state.previous })}>

                            //     
                            //     {/* <p className="text-show-change">
                            //         Ver {this.state.previous ? <span className="text-orange"> estado actual del río</span> : <span className="text-orange"> estado anterior del río</span>}
                            //     </p> */}
                            // </div>
                        }

                        <PlaygroundModal show={this.state.show} title={this.state.title} icon={this.state.icon}
                            close={() => this.setState({ show: false })} user={userLogged} team={userLoggedTeam} id={this.state.title.toLowerCase + '-icon'} />

                        {userLogged.role.name != "PLAYER" &&
                            <RankingModal show={this.state.showRankingModal} close={() => this.setState({ showRankingModal: false })} team={userLoggedTeam} />
                        }

                        {(nextStep?.title.includes("Amenaza")) &&
                            <ThreatComponent className="threat-info" user={userLogged} step={nextStep} team={userLoggedTeam} />
                        }

                        {(nextStep?.title.includes("Amenaza")) &&
                            <Space className="iconsHorizontal">
                                <ThreatPlayComponent user={userLogged} step={nextStep} team={userLoggedTeam} />
                            </Space>
                        }

                        {userLoggedTeam?.step.order == 24 &&
                            <div className="background-show-changes" onClick={() => this.setState({ first: !this.state.first })}>
                                <Button className="button-show-changes-with-image">
                                    <img src={showChange} className="show-changes" alt="Ver estado" />
                                </Button>
                                <p className="text-show-change">
                                    Ver {this.state.first ? <span className="text-orange"> río en buen estado</span> : <span className="text-orange"> estado inicial del río</span>}
                                </p>
                            </div>
                        }

                        <AvatarModal show={this.state.showAvatarModal} close={() => this.setState({ showAvatarModal: false })} userTeam={userLoggedTeam} user={userLogged} />
                        <ManualModal show={this.state.showManualModal} close={() => this.setState({ showManualModal: false })} />
                        {userLogged.role.id != 1 && <EditorPlayerModal show={this.state.showEditorPlayerModal} close={() => this.setState({ showEditorPlayerModal: false })} user={userLogged} />}
                        <EndGameModal show={this.state.showEndGameModal} close={() => this.setState({ showEndGameModal: false })} user={userLogged} />
                        <LevelsModal show={this.state.showLevelsModal} close={() => this.setState({ showLevelsModal: false })} user={userLogged} userTeam={userLoggedTeam} />
                    </Content>
                </Layout>
            </>
        )
    }
}

export default inject('teamsStore', 'organizationsStore', 'generalStore', 'usersStore')(observer(Main));