import React from 'react';
import Phaser from 'phaser';

import { inject, observer } from 'mobx-react';

import { Button, Row, Col } from 'antd';

import { Preloader } from './scenes/preloader';
import { StartScene } from './scenes/startScene';
import { FirstLevel } from './scenes/firstLevel';
import { SecondLevel } from './scenes/secondLevel';
import { ThirdLevel } from './scenes/thirdLevel';
import { FourthLevel } from './scenes/fourthLevel';
import { FifthLevel } from './scenes/fifthLevel';
import { SixthLevel } from './scenes/sixthLevel';
import { LastScene } from './scenes/lastScene';
import '../MyGame.scss';

import { Modal } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';

import exit from '../../../assets/images/buttons/IconoSalir-PG.png';
import info from '../../../assets/images/buttons/IconoAviso_Legal-PG.png';
import { Space } from 'antd';

class MyGame extends React.Component {
    constructor(props) {
        super(props);
        this.game = {
            title: ''
        };
        this.state = {
            showInfo: false,
            alt: '',
            isHovered: false
        };

        this.levels = [
            { id: 1, name: 'firstLevel' },
            { id: 2, name: 'secondLevel' },
            { id: 3, name: 'thirdLevel' },
            { id: 4, name: 'fourthLevel' },
            { id: 5, name: 'fifthLevel' },,
            { id: 6, name: 'sixthLevel' },
            { id: 7, name: 'lastScene' }
        ]
    }

    //*********************************************************
    componentDidMount() {
        this.handleLoadTeamsData();

        const config = {
            width: 2000,
            height: 1020,
            title: 'Memorik Game',
            parent: 'phaser-game',
            type: Phaser.AUTO,
            scale: {
                mode: Phaser.Scale.FIT,
                autoCenter: Phaser.Scale.CENTER_BOTH
            },
            scene: [Preloader, StartScene, FirstLevel, SecondLevel, ThirdLevel, FourthLevel, FifthLevel, SixthLevel, LastScene],
        };

        this.game = new Phaser.Game(config);
        let level = this.props.usersStore.userLogged.memorikLevel;
        console.log("UserLogged: ", this.props.usersStore.userLogged)
        let nameLevel = this.levels.find(item => level == item.id)?.name
        console.log("Level: ", nameLevel);
        this.game.level = nameLevel;
    }

    //*********************************************************
    handleLoadTeamsData = () => {
        if (this.props.teamsStore != undefined) {
            this.props.teamsStore.doActionGetTeamsList();
        }
    }

    //*********************************************************
    closeWindow = () => {
        let data = {}
        let player = this.props.usersStore.userLogged;
        let score = this.game.score;
        let passed = this.game.passed;
        let initialScore = this.game.initialScore;
        let numLevel = this.game.numLevel;

        if (passed && score > 0) {
            data.id = player.id;
            data.score = score;
            data.game = "memorik";
            data.level = numLevel;
            this.props.usersStore.doActionSaveScore(data);
        } else if (!passed && score > 0) {
            data.id = player.id;
            data.score = initialScore;
            data.game = "memorik";
            data.level = numLevel;
            this.props.usersStore.doActionSaveScore(data);
        }
        setTimeout(() => {
            this.game.destroy();
            window.history.back();
        }, 1000);
    }

    //*********************************************************
    render() {
        return (
            <>
                <Space direction="vertical" className="iconsVertical-game" >
                    <div className='image-container' onClick={() => this.closeWindow()} onMouseEnter={() => this.setState({ alt: "Cerrar", isHovered: true })}
                        onMouseLeave={() => this.setState({ isHovered: false })}>
                        <img src={exit} className="buttonIconsConfig" />
                        {this.state.isHovered && <div className="alt-text">{this.state.alt}</div>}
                    </div>
                    <div className='image-container' onClick={() => this.setState({ showInfo: true })} onMouseEnter={() => this.setState({ alt: "Instrucciones", isHovered: true })}
                        onMouseLeave={() => this.setState({ isHovered: false })}>
                        <img src={info} className="buttonIconsConfig" />
                        {this.state.isHovered && <div className="alt-text">{this.state.alt}</div>}
                    </div>
                </Space>
                {this.state.showInfo &&
                    <Modal
                        className="manualmodal"
                        title={
                            <Row justify='center' align="top">
                                <Col className="title-background" sm={{ span: 16 }} md={{ span: 16 }}>
                                    <div className="title">
                                        Instrucciones
                                    </div>
                                </Col>
                            </Row>}
                        closeIcon=
                        {
                            <Button onClick={() => this.setState({ showInfo: false })} shape="circle" id="close-button" >
                                <CloseCircleOutlined className="close-circle" />
                            </Button>
                        }
                        open={this.state.showInfo}
                        width="max-content"
                        bodyStyle={{ overflowY: 'scroll' }}
                        closable={true}
                        maskClosable={true}
                        footer={null}
                    >
                        <div className="content-manual">
                            <>  <p>Los ríos no son solo agua: están formados también por sedimentos, nutrientes y especies de flora y fauna. </p>
                                <p>¿Sabías que, a pesar de cubrir menos del 1% de la superficie de la Tierra, son hábitat para casi el 10% de las especies conocidas?</p>
                                <p>Descubre algunas especies de flora y fauna que viven en los ríos cantábricos jugando a nuestro Memorik.</p>
                            </>
                        </div>
                    </Modal>}
                <div id='phaser-game' className='game'></div>
            </>)
    }
}

export default inject('teamsStore', 'organizationsStore', 'generalStore', 'usersStore')(observer(MyGame));