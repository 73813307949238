import { flow, makeAutoObservable } from "mobx";
import { makePersistable } from "mobx-persist-store";
import { callServiceEventAware } from ".";
import { getPaginatedListOfPlayers, getPlayersListService, removePlayerService, saveMultiPlayersService, savePlayerEditService, changeEnabledPlayerStateService } from "../services/PlayerService";

import * as Actions from './actionFlags.js';
import generalStore from "./generalStore";

class playersStore {
    players = {};
    changePlayerDataFlag = false;
    playersFilter = {};
    playersOrderBy = "user.username_ASC";
    resetPlayersList = false;
    resetFilter = false; 
    message = "";
    error = "";

    //*********************************************************
    constructor() {
        makeAutoObservable(this, {}, { autoBind: true });
        makePersistable(this, { name: 'playersStore', properties: ['players', 'playersFilter', 'playersOrderBy'], storage: window.sessionStorage });
    }

    //*********************************************************
    doActionRemoveMessage = flow(function* () {
        this.message = "";
    });

    //*********************************************************
    doActionChangeOrderBy = flow(function* (orderBy) {
        this.playersOrderBy = orderBy;
    });

    //*********************************************************
    doActionGetPaginatedListOfPlayers = flow(function *(resetList, filter, page){
        try {
            const response = yield callServiceEventAware(Actions.GET_LIST_OF_TEAM_PLAYERS, getPaginatedListOfPlayers, filter, page, this.playersOrderBy);
            const players = response.data;

            let currentPlayers;
            let hasMore = true;

            if (page > 0) {
                currentPlayers = this.players.list;

                if (players.length > 0){
                    currentPlayers = currentPlayers.concat(players);
                }
                else {
                    hasMore = false;
                }
            }
            else 
                currentPlayers = players;

            this.resetPlayersList = resetList;
            this.playersFilter = filter;
            this.players = { hasMore: hasMore, page: page, list: currentPlayers };

        } catch (error) {
            console.log("ERROR EN LA LLAMADA AL API EN playersStore.js: " + error.message);
            console.log(error);
            generalStore.doActionEndService(Actions.GET_PAGINATED_LIST_OF_TEAM_PLAYERS);
            generalStore.doActionGeneralError("Error accediendo al servidor: " + error.message);
        }
    });

    //*********************************************************
    doActionGetPlayersList = flow(function *() {
        try {
            const response = yield callServiceEventAware(Actions.GET_LIST_OF_TEAM_PLAYERS, getPlayersListService);
            this.players.list = response.data;
        } catch (error) {
            console.log("ERROR EN LLAMADA AL API EN playersStore.js: " + error.message);
            console.log(error);
            generalStore.doActionEndService(Actions.GET_LIST_OF_TEAM_PLAYERS);
            generalStore.doActionGeneralError("Error accediendo al servidor: " + error.message);
        }
    });

    //*********************************************************
    doActionFilterPlayers = flow(function *(item) {
        this.playersFilter = { idTeam: item.id, idAcademicYear: item.academicYear.id };
        this.doActionGetPaginatedListOfPlayers(true, this.playersFilter, 0);
        generalStore.doActionSetTeamsPanelActiveTab("2");
    })

    //*********************************************************
    // doActionSavePlayerEdit = flow(function *(data){
    //     let response;
    //     try {
    //         response = yield callServiceEventAware(Actions.TEAM_PLAYER_SAVE_DATA, savePlayerEditService, data);
    //         const player = response.data;            
    //         let currentPlayers = [];

    //         if (this.players.list == undefined)
    //             this.players = { list: [player] };
    //         else {
    //             currentPlayers = [player, ...this.players.list.filter(item => item.id != player.id)];
    //             currentPlayers.sort(this.sortPlayers(this.playersOrderBy));

    //             this.message = "Los datos han sido guardados correctamente";
    //             this.players = { list: currentPlayers };
    //         }            
    //     } catch (error) {
    //         generalStore.doActionEndService(Actions.TEAM_PLAYER_SAVE_DATA);
    //         console.log("ERROR EN LLAMADA AL API EN playersStore.js: " + error.message);
    //         console.log(error);

    //         if (error.response != null) {
    //             let code = error.response.data.code;
    //             let messageE = error.response.data.message;
    //             if (error.response.status == 422 && code == 401) {
                    
    //                 generalStore.doActionGeneralError("Error accediendo al servidor: " + messageE);
    //             }
    //             else {
    //                 generalStore.doActionGeneralError("Error accediendo al servidor: " + error.message);
    //             }
    //         }
    //         else generalStore.doActionGeneralError("Se ha producido un error: " + error.message);
    //     }
        
    //     return response;
    // });

    //*********************************************************
    doActionSaveMultiPlayers = flow(function *(data){
        try {
            const response = yield callServiceEventAware(Actions.TEAM_PLAYER_SAVE_DATA, saveMultiPlayersService, data);
            const players = response.data;

            let currentPlayers = [];

            if (this.players.list == undefined) 
                this.players = { list: players };
            else {
                for (let i = 0; i < players.length; i++) {
                    currentPlayers = [players[i], ...this.players.list];
                    this.players = { list: currentPlayers };
                    currentPlayers.sort(this.sortPlayers(this.playersOrderBy));
                }
                this.message = "Los datos se han guardado correctamente";
            }
        } catch (error) {
            generalStore.doActionEndService(Actions.TEAM_PLAYER_SAVE_DATA);
            console.log("ERROR EN LLAMADA AL API EN playersStore.js: " + error.message);
            console.log(error);

            if (error.response != null) {
                if (error.response.status == 422) {
                    generalStore.doActionGeneralError("Error al guardar los datos");
                }
                else {
                    generalStore.doActionGeneralError("Error accediendo al servidor: " + error.message);
                }
            }
            else generalStore.doActionGeneralError("Se ha producido un error: " + error.message);
        }
    });

    //*********************************************************
    doActionChangeEnabledPlayerState = flow(function* (player, disabled) {
        try {
            changeEnabledPlayerStateService(player, disabled);

            const status = disabled ? "desactivado/a" : "activado/a";

            if (this.players.list == undefined)
                this.message = "El/La jugador/a ha sido " + status;
            else {
                let modifiedPlayer = this.players.list.filter(item => item.id == player.id)[0];
                modifiedPlayer.user.disabled = disabled;

                let currentPlayers = [modifiedPlayer, ...this.players.list.filter(item => item.id != player.id)];
                currentPlayers.sort(this.sortPlayers(this.playersOrderBy));

                this.message = "El/La jugador/a ha sido " + status;
                this.players = { list: currentPlayers };
            }
        } catch (error) {
            console.log("ERROR EN LLAMADA AL API EN playersStore.js: " + error.message);
            console.log(error);
            generalStore.doActionGeneralError("Error accediendo al servidor: " + error.message);
        }
    });

    //*********************************************************
    doActionRemovePlayer = flow(function *(data) {
        try {
            yield callServiceEventAware(Actions.TEAM_PLAYER_REMOVE, removePlayerService, data);

            if (this.players.list != undefined) {
                let currentPlayers = [...this.players.list.filter(item => item.id != data)];

                this.doActionGetPaginatedListOfPlayers(true, this.playersFilter, this.players.page);

                this.message = "Se ha eliminado correctamente el jugador/a"
                this.players = { list: currentPlayers };
            }

        } catch (error) {
            generalStore.doActionEndService(Actions.TEAM_PLAYER_REMOVE);
            console.log("ERROR EN LLAMADA AL API EN playersStore.js: " + error.message);
            console.log(error);

            if (error.response != null) {
                if (error.response.status == 500) {
                    generalStore.doActionGeneralError("Error al eliminar el jugador/a");
                }
                else if (error.response.status == 409) {
                    generalStore.doActionGeneralError("Error al eliminar el jugador/a. Actualmente está en uso.");
                }
                else {
                    generalStore.doActionGeneralError("Error accediendo al servidor: " + error.message);
                }
            }
            else generalStore.doActionGeneralError("Se ha producido un error: " + error.message);
        }
    });

    //*********************************************************
    sortPlayers(sortBy) {     
        return function (a, b) {
            let order = sortBy.split("_");

            if (order[1].toUpperCase() == "ASC") {
                return eval('a.' + order[0] + '.toString().localeCompare(b.' + order[0] + '.toString())');
            } else
                return eval('b.' + order[0] + '.toString().localeCompare(a.' + order[0] + '.toString())');
        }
    }
    
}

export default new playersStore();