import Phaser from "phaser";

export class LastScene extends Phaser.Scene {

    score = 0;

    initialScore = 0;

    passed = true;

    numLevel = 5;

    close = false;

    /**
     * Constructor Escena Inicial
     */
    constructor() {
        super({
            key: 'lastScene'
        });
    }

    /**
     * Método para asignar valores iniciales a las propiedades de la escena.
     * <br>
     * Se ejecuta antes del método create.
     */
    init() {
        this.cameras.main.fadeIn(500);
    }

    /**
    * Método para crear todos los elementos de la escena.
    */
    create(data) {
        // imagen de fondo
        this.add.image(1000, 510, 'bg');
        let titleName = this.game.title;

        if (data.score == undefined) data.score = 0;
        this.score = data.score;
        this.initialScore = data.score;
        // título
        const title = this.add.text(792, 135, titleName,
            { align: 'center', fontStyle: 'Bold', fontSize: 104, fontFamily: "Quicksand" });
        // texto para iniciar
        const text = this.add.text(480, 150, '¡Enhorabuena, has superado todos los niveles de La palabra revuelta!\nPulsa en el botón cerrar para volver al panel de juego.',
            { align: 'center', fontSize: 34, fontFamily: "Quicksand" });
        //text.x = this.game.scale.width / 2 - text.displayWidth;

        this.game.events.emit("POINTS", this.score);
        this.game.events.emit("PASSED LEVEL", this.passed);
        this.game.events.emit("INITIALS POINTS", this.initialScore);
        this.game.events.emit("PASSED NUM LEVEL", this.numLevel);

        // botón de iniciar
        // const startbtn = this.add.image(1010, 510, 'backBtn').setScale(0.4).setInteractive({ cursor: 'pointer' });
        // // animación del botón
        // this.add.tween({
        //     targets: startbtn,
        //     scale: 0.35,
        //     duration: 1500,
        //     yoyo: true,
        //     repeat: -1
        // });

        // this.close = false;

        // // evento al pulsar el botón
        // startbtn.on('pointerdown', () => {
        //     console.log(this.close);
        //     this.close = true;
        //     this.game.events.emit("CLOSE", this.close);
        //     console.log(this.close)
        //     this.add.tween({
        //         targets: [text, title, startbtn],
        //         duraction: 2000,
        //         y: -1000,
        //         onComplete: () => {
        //             this.game.events.emit("CLOSE", this.close);
        //             this.game.destroy();
        //         }
        //     });
        // });
    }

    update() {
        this.game.score = this.score;
        this.game.passed = this.passed;
        this.game.initialScore = this.initialScore;
        this.game.numLevel = this.numLevel;
        this.game.close = this.close;
    }
}