import Phaser from "phaser";

export class FirstLevel extends Phaser.Scene {
    elements = [];

    canMove = false;

    attempts = 0;

    score = 0;

    answers = [];

    initialLength = 0;

    correctAnswer = 0;

    correctAnswers = '';

    zones = [];

    passed = false;

    numLevel = 1;

    elementDragged = null;

    // *******************************************************************************
    constructor() {
        super(
            {
                key: 'firstLevel'
            }
        )
    }

    // *******************************************************************************
    init() {
        this.cameras.main.fadeIn(500);
        this.elements = [
            {
                id: 1,
                title: '¿Cómo se forma un río?',
                image: 'image1-1',
                answers: [
                    { text: 'Precipitación', x: 380, y: 400 },
                    { text: 'Agua subterránea', x: 340, y: 630 },
                    { text: 'Escorrentía superficial', x: 560, y: 560 }
                ]
            },
            {
                id: 2,
                title: 'Los tramos de los ríos son...',
                image: 'image1-2',
                answers: [
                    { text: 'Tramo medio', x: 760, y: 660 },
                    { text: 'Desembocadura', x: 670, y: 760 },
                    { text: 'Cabecera', x: 680, y: 570 }
                ]
            },
            {
                id: 3,
                title: 'Las partes del río son...',
                image: 'image1-3',
                answers: [
                    { text: 'Cauce', x: 670, y: 700 },
                    { text: 'Llanura de inundación', x: 880, y: 670 },
                    { text: 'Ribera', x: 540, y: 600 },
                    { text: 'Acuífero', x: 380, y: 790 }
                ]
            },
            {
                id: 4,
                title: 'Los estados del agua son...',
                image: 'image1-4',
                answers: [
                    { text: 'Sólido', x: 535, y: 440 },
                    { text: 'Gaseoso', x: 980, y: 400 },
                    { text: 'Líquido', x: 840, y: 660 }
                ]
            },
            {
                id: 5,
                title: 'Las partes del río son...',
                image: 'image1-5',
                answers: [
                    { text: 'Cauce', x: 700, y: 700 },
                    { text: 'Ribera', x: 900, y: 500 },
                    { text: 'Llanura de inundación', x: 380, y: 500 }
                ]
            },
            {
                id: 6,
                title: 'Obras que sirven para captar agua de los ríos:',
                image: 'image1-6',
                answers: [
                    { text: 'Azud', x: 470, y: 350 },
                    { text: 'Presa', x: 850, y: 650 },
                    //{ text: 'Embalse', x: 700, y: 600 }
                ]
            }
            ];
        this.initialLength = this.elements.length;
        this.shuffle();
        this.attempts = 3;
    }

    // *******************************************************************************
    shuffle() {
        var rnd = new Phaser.Math.RandomDataGenerator();

        this.element = this.elements.length > 0 ? rnd.pick(this.elements) : "";
    }

    // *******************************************************************************
    create() {
        //background y título
        this.add.image(this.game.scale.width / 2, this.game.scale.height / 2, 'bg').setDepth(-1);

        const title = this.add.text(0, -1000, "Conexión fluvial", {
            align: 'center', fontSize: 45, fontFamily: "Quicksand"
        });

        title.x = this.game.scale.width / 2 - title.width / 2;

        this.add.tween({
            targets: title,
            ease: Phaser.Math.Easing.Expo.InOut,
            duration: 1000,
            y: 20
        });

        // icono puzzle y su animación (de derecha a izquierda)
        const puzzle = this.add.image(10000, 70, 'icon');
        puzzle.setScale(0.02);

        this.add.tween({
            targets: puzzle,
            ease: Phaser.Math.Easing.Expo.InOut,
            duration: 1000,
            x: this.scale.width - 100
        });

        // nombre de tanda y su animación (de izquierda a derecha)
        this.levelContainer = this.add.container(-100, 100);

        const level = this.add.text(0, 0, 'NIVEL 1',
            { align: 'center', fontStyle: "bold", fontSize: 30, fontFamily: "Quicksand", color: '#f29602' }).setDepth(1);
        level.setOrigin(0.5, 0.5);

        const levelBg = this.createBackground(level, 0xffffff);

        this.levelContainer.add([levelBg, level]);

        this.add.tween({
            targets: this.levelContainer,
            ease: Phaser.Math.Easing.Expo.InOut,
            duration: 1000,
            x: 100
        });

        // score y su animación (de izquierda a derecha)
        this.scoreContainer = this.add.container(-400, 100);

        this.scoreText = this.add.text(0, 0, 'PUNTUACIÓN | ' + this.score,
            { align: 'center', fontStyle: "bold", fontSize: 30, fontFamily: "Quicksand", color: '#f29602' }).setDepth(1);
        this.scoreText.setOrigin(0.5, 0.5);

        this.scoreBg = this.createBackground(this.scoreText, 0xffffff);

        this.scoreContainer.add([this.scoreBg, this.scoreText]);

        this.add.tween({
            targets: this.scoreContainer,
            ease: Phaser.Math.Easing.Expo.InOut,
            duration: 1000,
            x: 330
        });

        this.correctAnswers = this.add.text(1350, 100, "Aciertos: " + this.correctAnswer + " de " + this.initialLength,
            { align: 'center', fontStyle: "bold", fontSize: 35, fontFamily: "Quicksand", color: '#fff' }
        );

        // creamos las gotas (vidas) y se las asignamos a la variable this.waterDrops
        this.waterDrops = this.createWaterDrops();

        this.game.events.emit("POINTS", this.score);
        this.game.events.emit("PASSED LEVEL", this.passed);
        this.game.events.emit("PASSED NUM LEVEL", this.numLevel);

        this.startGame();
    }

    // *******************************************************************************
    createBackground(item, color) {
        const textWidth = item.width;
        const textHeight = item.height;

        // Crear el objeto gráfico para la pastilla de fondo
        const padding = 35; // Espacio adicional alrededor del texto
        const background = this.add.graphics();
        background.fillStyle(color, 1);

        // Dibujar un rectángulo redondeado como fondo
        background.fillRoundedRect(
            item.x - textWidth / 2 - padding / 2, // X posición (centrada)
            item.y - textHeight / 2 - padding / 2, // Y posición (centrada)
            textWidth + padding, // Ancho del rectángulo
            textHeight + padding, // Altura del rectángulo
            30 // Radio de las esquinas redondeadas
        );

        background.lineStyle(3, 0xffffff)

        background.strokeRoundedRect(
            item.x - textWidth / 2 - padding / 2, // posición x (centrada)
            item.y - textHeight / 2 - padding / 2, // posición y (centrada)
            textWidth + padding, // ancho (ajustado al grosor del borde)
            textHeight + padding, // alto (ajustado al grosor del borde)
            30
        );
        background.setDepth(-1);

        return background;
    }

    // *******************************************************************************
    createWaterDrops() {
        return Array.from(new Array(this.attempts)).map((el, index) => {
            const waterDrop = this.add.image(-1000, 980, 'drop')
                .setScale(0.5).setDepth(1);

            const waterDropBox = this.add.image(0, 980, 'textBg').setTintFill(0xFFFFFF).setScale(0.9);

            waterDropBox.x = (this.game.scale.width / 2 - waterDrop.width / 2) + 35 * index;

            this.add.tween({
                targets: [waterDrop],
                ease: Phaser.Math.Easing.Expo.InOut,
                duration: 1000,
                delay: 500 + index * 200,
                x: (this.game.scale.width / 2 - waterDrop.width / 2) + 35 * index, // marginLeft + spaceBetween * index
                onComplete: () => {
                    this.canMove = true;
                }
            });

            waterDrop.box = waterDropBox;

            return waterDrop;
        });
    }

    // *******************************************************************************
    startGame() {
        //this.check = false;
        this.element.answers.map(item => {
            console.log(item.text)
        });

        this.image?.destroy();
        this.titleContainer?.destroy();

        // image
        this.image = this.add.image(700, 600, this.element.image).setScale(0.7).setDepth(-1);
        // title
        this.title = this.add.text(0, 0, this.element.title, {
            align: 'center', fontSize: 34, fontFamily: "Quicksand", fontStyle: "bold"
        }).setOrigin(0.5, 0.5);
        const titleBg = this.createBackground(this.title, 0x93b64a);

        this.titleContainer = this.add.container(1500, 300, [titleBg, this.title]);

        this.element.answers.map((item, index) => {
            var zone = this.physics.add.image(item.x, item.y, 'o').setScale(0.8);

            zone.busy = false;

            var answer = this.add.text(0, 0, item.text,
                { align: 'center', fontStyle: "bold", fontSize: 24, fontFamily: "Quicksand", color: '#ffffff' });
            answer.setOrigin(0.5, 0.5);

            zone.setDepth(-1)

            var answerBg = this.createBackground(answer, 0xf29602);

            var answerContainer = this.add.container(1500, 450 + 100 * index, [answerBg, answer]);

            answerContainer.answer = answer;

            answerContainer.setSize(answer.width, answer.height);

            answerContainer.setInteractive(new Phaser.Geom.Rectangle(0, 0, answer.width, answer.height), Phaser.Geom.Rectangle.Contains,
                { cursor: 'pointer' })

            this.input.setDraggable(answerContainer);

            this.physics.add.existing(answerContainer);
            answerContainer.body.setCollideWorldBounds(true);

            this.answers.push(answerContainer);
            this.zones.push(zone);

            this.input.on('drag', (pointer, gameObject, dragX, dragY) => {
                gameObject.x = dragX;
                gameObject.y = dragY;
                this.elementDragged = gameObject;
                this.check = false;
            });
        });
    }

    // *******************************************************************************
    update() {
        this.scoreText.setText('PUNTUACIÓN |  ' + this.score);
        this.correctAnswers.setText("Aciertos: " + this.correctAnswer + " de " + this.initialLength);

        if (this.attempts == 0) {
            this.gameOver();
            this.attempts -= 1;
        }

        if (this.passed) {
            this.game.passed = this.passed;
            this.nextLevel();
            this.passed = false;
        }

        this.zones.map(item => {
            this.input.on('dragend', () => {
                var text = this.elementDragged.answer.text;
                this.check = true;
                this.physics.add.overlap(this.elementDragged, item, () => {
                    item.busy = true;
                    item.text = text;
                });
                if (!this.physics.overlap(this.elementDragged, item)) {
                    item.busy = false;
                }
            });
        })

        var every = this.zones.every(item => {
            return item.busy == true;
        })

        if (this.check && every) {
            this.answers.map(item =>
                item.disableInteractive()
            );
            this.checkAnswers();
            this.check = false;
        }

        this.game.score = this.score;
        this.game.numLevel = this.numLevel;
    }

    // *******************************************************************************
    restartGame() {
        this.cameras.main.fadeOut(2000);
        this.images = [];
        this.time.addEvent({
            delay: 100 * this.element.answers.length,
            callback: () => {
                this.answers = [];
                this.zones = [];
                this.score = 0;
                this.correctAnswer = 0;
                this.canMove = false;
                this.scene.restart();
            }
        })
    }

    // *******************************************************************************
    checkAnswers() {
        const checkContainer = this.add.container(0, 0);

        const checkLevel = this.add.image(1800, 900, 'checkBg').setScale(0.5).setTintFill(0xf19800);

        const checkBtn = this.add.image(1795, 900, 'nextbtn').setScale(0.1).setInteractive({ cursor: 'pointer' });

        const checkLevelText = this.add.text(1705, 800, 'COMPROBAR',
            { align: 'center', fontStyle: "bold", fontSize: 30, fontFamily: "Quicksand", color: '#ffffff' })
            .setDepth(1)
            .setInteractive({ cursor: 'pointer' });

        checkContainer.add(checkLevel);
        checkContainer.add(checkBtn);

        checkContainer.add(checkLevelText);

        checkContainer.y = 300;

        this.add.tween({
            targets: checkContainer,
            duration: 1000,
            ease: Phaser.Math.Easing.Out,
            y: 0
        });

        // texto de acierto
        this.correctContainer = this.add.container(1500, 400);

        this.correctText = this.add.text(0, 0, '¡Perfecto! Sigue jugando',
            { align: 'center', fontStyle: "bold", fontSize: 30, fontFamily: "Quicksand", color: '#f29602' }).setDepth(1);
        this.correctText.setOrigin(0.5, 0.5);

        this.correctBg = this.createBackground(this.correctText, 0xffffff);

        this.correctContainer.add([this.correctBg, this.correctText]);

        this.correctContainer.setScale(0);

        // creamos icono de acierto
        const correctAnswer = this.add.image(1500, 600, 'ok').setOrigin(.5)
            .setDepth(3)
            .setInteractive()
            .setScale(0);


        // texto de fallo
        this.wrongContainer = this.add.container(1500, 400);

        this.wrongText = this.add.text(0, 0, 'Esta vez no has acertado, ¡inténtalo de nuevo!',
            { align: 'center', fontStyle: "bold", fontSize: 30, fontFamily: "Quicksand", color: '#f29602' }).setDepth(1);
        this.wrongText.setOrigin(0.5, 0.5);

        this.wrongBg = this.createBackground(this.wrongText, 0xffffff);

        this.wrongContainer.add([this.wrongBg, this.wrongText]);

        this.wrongContainer.setScale(0);

        // creamos icono de respuesta incorrecta
        const wrongAnswer = this.add.image(1500, 600, 'x').setOrigin(.5)
            .setDepth(3)
            .setInteractive()
            .setScale(0);

        var checkAnswers = this.element.answers.map(item => { return this.zones.find(zone => zone.text == item.text && zone.x == item.x && zone.y == item.y) });
        console.log(checkAnswers);

        checkBtn.on(Phaser.Input.Events.POINTER_DOWN, () => {
            checkContainer.destroy();
            // ***************************** FALLO *****************************
            if (checkAnswers.some(item => item == undefined)) {
                // perdemos un intento (una gota)
                const lastwaterDrop = this.waterDrops[this.waterDrops.length - 1];
                this.attempts--;
                this.add.tween({
                    targets: lastwaterDrop,
                    duration: 1000,
                    alpha: 0.3,
                    onComplete: () => {
                        this.waterDrops.pop();
                    }
                });
                // hacemos aparecer el icono de respuesta incorrecta
                this.add.tween({
                    targets: wrongAnswer,
                    scale: 0.2,
                    duration: 1000,
                    ease: Phaser.Math.Easing.In,
                    onComplete: () => {
                        setTimeout(() => {
                            wrongAnswer.destroy();
                            if (this.attempts > 0) {
                                this.answers?.map(item => item.destroy());
                                this.zones?.map(item => item.destroy());
                                this.answers = [];
                                this.zones = [];
                                checkContainer.destroy();
                                this.startGame();
                            }
                        }, 2000);
                    }
                });
                this.add.tween({
                    targets: this.wrongContainer,
                    scale: 1,
                    duration: 1200,
                    ease: Phaser.Math.Easing.In,
                    // al completarse, tras 2000 milisegundos (2 segundos) hacemos desaparecer el icono
                    onComplete: () => {
                        setTimeout(() => {
                            this.wrongContainer.destroy();
                        }, 1500);
                    }
                });
            }
            // ***************************** ACIERTO *****************************
            else {
                this.correctAnswer++;
                // aminación para mostrar el icono de tick verde 
                this.add.tween({
                    targets: correctAnswer,
                    scale: 0.2,
                    duration: 1200,
                    ease: Phaser.Math.Easing.In,
                    // al completarse, tras 2000 milisegundos (2 segundos) hacemos desaparecer el icono
                    onComplete: () => {
                        setTimeout(() => {
                            correctAnswer.destroy();
                            this.answers?.map(item => item.destroy());
                            this.zones?.map(item => item.destroy());
                            this.answers = [];
                            this.zones = [];
                            checkContainer.destroy();
                        }, 2000);
                    }
                });

                const scoretext = this.add.text(420, -20, '+10',
                    { align: 'center', fontStyle: "bold", fontSize: 22, fontFamily: "Quicksand", color: '#fff' });

                // animación de la puntuación obtenida
                this.add.tween({
                    targets: scoretext,
                    x: 420,
                    y: 80,
                    duration: 1500,
                    onUpdate: () => {
                        this.canMove = false;
                    },
                    onComplete: () => {
                        this.score += 10;
                        scoretext.destroy();
                    }
                });

                this.add.tween({
                    targets: this.correctContainer,
                    scale: 1,
                    duration: 1200,
                    ease: Phaser.Math.Easing.In,
                    // al completarse, tras 2000 milisegundos (2 segundos) hacemos desaparecer el icono
                    onComplete: () => {
                        setTimeout(() => {
                            this.correctContainer.destroy();
                        }, 2000);
                    }
                });
                // eliminamos la palabra correcta de la lista
                this.elements = this.elements.filter(item => item.id != this.element.id);

                // si no quedan palabras, pasamos de nivel
                if (this.elements.length <= 0) {
                    this.passed = true;
                    this.numLevel = 2;
                }
                else {
                    setTimeout(() => {
                        // si quedan palabras en este nivel volvemos a llamar al método que inicia el juego
                        if (this.elements.length > 0) {
                            this.startGame();
                        }
                        // habilitamos el juego de nuevo
                        this.canMove = true;
                    }, 4000);
                }

                this.shuffle();
            }
        })
    }

    // *******************************************************************************
    nextLevel() {
        // creamos los elementos que componen el cuadro para pulsar y pasar de nivel
        const winnerContainer = this.add.container(0, 0);

        const nextLevel = this.add.image(1800, 900, 'next').setAlpha(0.5).setTintFill(0xffffff);

        const nextBox1 = this.add.image(1790, 970, 'textBg').setTintFill(0xFFFFFF);
        const nextBox2 = this.add.image(1840, 970, 'textBg').setTintFill(0xFFFFFF);
        const nextBox3 = this.add.image(1890, 970, 'textBg').setTintFill(0xFFFFFF);

        const nextBtn = this.add.image(1845, 870, 'nextbtn').setScale(0.1).setInteractive({ cursor: 'pointer' });

        const nextLevelText = this.add.text(1755, 960, 'Siguiente nivel',
            { align: 'center', fontStyle: "bold", fontSize: 22, fontFamily: "Quicksand", color: '#F39C12' })
            .setDepth(1)
            .setInteractive({ cursor: 'pointer' });

        const winnerText = this.add.text(1840, 780, "¡Muy bien!",
            { align: "center", fontStyle: "bold", fontSize: 40, fontFamily: "Quicksand", color: "#fff" }
        ).setOrigin(.5)
            .setDepth(3);

        winnerContainer.add(nextLevel);
        winnerContainer.add(nextBtn);

        winnerContainer.add(winnerText);
        winnerContainer.add(nextBox1);
        winnerContainer.add(nextBox2);
        winnerContainer.add(nextBox3);
        winnerContainer.add(nextLevelText);

        winnerContainer.y = 1000;

        this.add.tween({
            targets: winnerContainer,
            duration: 1500,
            ease: Phaser.Math.Easing.Out,
            y: 1,
        });
        this.canMove = false;
        this.rotate = false;

        // al pulsar hacemos desaparecer los elementos de la pantalla 
        nextLevelText.on(Phaser.Input.Events.POINTER_DOWN, () => {
            this.nextLevelTweens(winnerContainer);
        });
        // mismo comportamiento que en el caso anterior
        nextBtn.on(Phaser.Input.Events.POINTER_DOWN, () => {
            this.nextLevelTweens(winnerContainer);
        });
    }

    // *******************************************************************************
    nextLevelTweens(winnerContainer) {
        this.waterDrops.map(item => this.add.tween({
            targets: item,
            duration: 2000,
            delay: 1000,
            y: 1500,
        }));

        this.add.tween({
            targets: [winnerContainer],
            duration: 2000,
            ease: Phaser.Math.Easing.Bounce.Out,
            delay: 1000,
            y: 1000,
            // al completarse la animación iniciamos el siguiente nivel
            onComplete: () => {
                this.scene.start('secondLevel', { score: this.score });
            }
        })
    }

    // *******************************************************************************
    gameOver() {
        this.canMove = false;

        // contenedor con todos los elementos del cuadro de volver a intentarlo
        const gameOverContainer = this.add.container(0, 0);
        const restartLevel = this.add.image(1800, 900, 'next').setAlpha(0.5).setTintFill(0xFFFFFF);

        const restartBox1 = this.add.image(1785, 970, 'textBg').setTintFill(0xFFFFFF);
        const restartBox2 = this.add.image(1840, 970, 'textBg').setTintFill(0xFFFFFF);
        const restartBox3 = this.add.image(1895, 970, 'textBg').setTintFill(0xFFFFFF);

        const restartBtn = this.add.image(1845, 870, 'restart').setScale(0.1).setInteractive({ cursor: 'pointer' });

        const restartLevelText = this.add.text(1740, 960, 'Vuelve a intentarlo',
            { align: 'center', fontStyle: "bold", fontSize: 22, fontFamily: "Quicksand", color: '#F39C12' })
            .setDepth(1)
            .setInteractive({ cursor: 'pointer' })

        const restartText = this.add.text(1840, 780, "¡Ups!",
            { align: "center", fontStyle: "bold", fontSize: 40, fontFamily: "Quicksand", color: "#fff" }
        ).setOrigin(.5)
            .setDepth(3);

        gameOverContainer.add(restartLevel);
        gameOverContainer.add(restartBtn);

        gameOverContainer.add(restartText);
        gameOverContainer.add(restartBox1);
        gameOverContainer.add(restartBox2);
        gameOverContainer.add(restartBox3);
        gameOverContainer.add(restartLevelText);

        gameOverContainer.y = 1000;

        this.add.tween({
            targets: gameOverContainer,
            duration: 1500,
            ease: Phaser.Math.Easing.Out,
            y: 0
        });

        restartBtn.on(Phaser.Input.Events.POINTER_DOWN, () => {
            this.gameOverTweens();
        });
        // mismo comportamiento en caso de pulsar en el texto
        restartLevelText.on(Phaser.Input.Events.POINTER_DOWN, () => {
            this.gameOverTweens();
        });
    }

    // *******************************************************************************
    gameOverTweens(gameOverContainer) {
        this.answers.map(item => {
            item.body.setCollideWorldBounds(false);
            this.add.tween({
                targets: [item],
                duration: 2000,
                delay: 500 + 200 * item,
                y: 3000,
            })
        });

        this.add.tween({
            targets: [this.image, this.titleContainer, gameOverContainer],
            duration: 1500,
            ease: Phaser.Math.Easing.Bounce.Out,
            y: 2000,
            onComplete: () => {
                this.restartGame();
            }
        })
    }
}