import Phaser from "phaser";
import configApp from '../../../../configApp.js';

/**
 * Clase para cargar los elementos del juego
 * @author Mehdi Lahbis Mhamdi
 * @class
 */
export class Preloader extends Phaser.Scene
{
    /**
     * Constructor para cargar los elementos
     */
    constructor()
    {
        super({
            key: 'Preloader'
        });
    }

    /**
    * Método para cargar los elementos del juego.
    */
    preload () {
        // fondo
        this.load.image("bg", configApp.getParams().PLAY_URL+'game/palabra/assets/fondo.png');

        // icono puzzle y linea
        this.load.image('icon', configApp.getParams().PLAY_URL+'game/palabra/assets/icono.png');
        
        //boton naranja comenzar
        this.load.image('startbtn', configApp.getParams().PLAY_URL+'game/palabra/assets/btnStart.png');

        //boton salir
        this.load.image('backBtn', configApp.getParams().PLAY_URL+'game/palabra/assets/back.png');

        //cuadro y boton next
        this.load.image('nextbtn', configApp.getParams().PLAY_URL+'game/palabra/assets/btnNext.png');
        this.load.image("next", configApp.getParams().PLAY_URL+'game/palabra/assets/cuadroNext.png');

        //icono ?
        this.load.image('questionIcon', configApp.getParams().PLAY_URL+'game/palabra/assets/question.png');

        //icono ok
        this.load.image('check', configApp.getParams().PLAY_URL+'game/palabra/assets/ok.png');

        // icono volver a intentar
        this.load.image('restart', configApp.getParams().PLAY_URL+'game/palabra/assets/tryAgainBtn.png');

        //viñeta letras
        this.load.image('lettersBag', configApp.getParams().PLAY_URL+'game/palabra/assets/bocadilloLetrasBordes.png');

        //viñeta pista
        this.load.image('clueBag', configApp.getParams().PLAY_URL+'game/palabra/assets/bocadilloPistaBordes (2).png');

        // Gota y su fondo
        this.load.image('drop', configApp.getParams().PLAY_URL+'game/palabra/assets/waterDrop.png');
        this.load.image('textBg', configApp.getParams().PLAY_URL+'game/palabra/assets/textBg.png');

        // letras
        this.load.image(' ', configApp.getParams().PLAY_URL+'game/palabra/assets/letras/letraHueco.png');
        this.load.image('a', configApp.getParams().PLAY_URL+'game/palabra/assets/letras/a.png');
        this.load.image('b', configApp.getParams().PLAY_URL+'game/palabra/assets/letras/b.png');
        this.load.image('c', configApp.getParams().PLAY_URL+'game/palabra/assets/letras/c.png');
        this.load.image('d', configApp.getParams().PLAY_URL+'game/palabra/assets/letras/d.png');
        this.load.image('e', configApp.getParams().PLAY_URL+'game/palabra/assets/letras/e.png');
        this.load.image('f', configApp.getParams().PLAY_URL+'game/palabra/assets/letras/f.png');
        this.load.image('g', configApp.getParams().PLAY_URL+'game/palabra/assets/letras/g.png');
        this.load.image('h', configApp.getParams().PLAY_URL+'game/palabra/assets/letras/h.png');
        this.load.image('i', configApp.getParams().PLAY_URL+'game/palabra/assets/letras/i.png');
        this.load.image('j', configApp.getParams().PLAY_URL+'game/palabra/assets/letras/j.png');
        this.load.image('k', configApp.getParams().PLAY_URL+'game/palabra/assets/letras/k.png');
        this.load.image('l', configApp.getParams().PLAY_URL+'game/palabra/assets/letras/l.png');
        this.load.image('m', configApp.getParams().PLAY_URL+'game/palabra/assets/letras/m.png');
        this.load.image('n', configApp.getParams().PLAY_URL+'game/palabra/assets/letras/n.png');
        this.load.image('ñ', configApp.getParams().PLAY_URL+'game/palabra/assets/letras/n_1.png');
        this.load.image('o', configApp.getParams().PLAY_URL+'game/palabra/assets/letras/o.png');
        this.load.image('p', configApp.getParams().PLAY_URL+'game/palabra/assets/letras/p.png');
        this.load.image('q', configApp.getParams().PLAY_URL+'game/palabra/assets/letras/q.png');
        this.load.image('r', configApp.getParams().PLAY_URL+'game/palabra/assets/letras/r.png');
        this.load.image('s', configApp.getParams().PLAY_URL+'game/palabra/assets/letras/s.png');
        this.load.image('t', configApp.getParams().PLAY_URL+'game/palabra/assets/letras/t.png');
        this.load.image('u', configApp.getParams().PLAY_URL+'game/palabra/assets/letras/u.png');
        this.load.image('v', configApp.getParams().PLAY_URL+'game/palabra/assets/letras/v.png');
        this.load.image('w', configApp.getParams().PLAY_URL+'game/palabra/assets/letras/w.png');
        this.load.image('x', configApp.getParams().PLAY_URL+'game/palabra/assets/letras/x.png');
        this.load.image('y', configApp.getParams().PLAY_URL+'game/palabra/assets/letras/y.png');
        this.load.image('z', configApp.getParams().PLAY_URL+'game/palabra/assets/letras/z.png');

        // letras con tildes
        this.load.image('á', configApp.getParams().PLAY_URL+'game/palabra/assets/letras/a_1.png');
        this.load.image('é', configApp.getParams().PLAY_URL+'game/palabra/assets/letras/e_1.png');
        this.load.image('í', configApp.getParams().PLAY_URL+'game/palabra/assets/letras/i_1.png');
        this.load.image('ó', configApp.getParams().PLAY_URL+'game/palabra/assets/letras/o_1.png');
        this.load.image('ú', configApp.getParams().PLAY_URL+'game/palabra/assets/letras/u_1.png');

        // Imágenes
        // Tanda 1
        this.load.image("acuifero", configApp.getParams().PLAY_URL+'game/palabra/assets/tanda1/acuifero.png');
        this.load.image("bosque", configApp.getParams().PLAY_URL+'game/palabra/assets/tanda1/bosque.png');
        this.load.image("cascada", configApp.getParams().PLAY_URL+'game/palabra/assets/tanda1/cascada.jpg');
        this.load.image("cauce", configApp.getParams().PLAY_URL+'game/palabra/assets/tanda1/cauce.png');
        this.load.image("desembocadura",configApp.getParams().PLAY_URL+ 'game/palabra/assets/tanda1/desembocadura.JPG');
        this.load.image("ecosistema",configApp.getParams().PLAY_URL+ 'game/palabra/assets/tanda1/ecosistema.jpg');
        this.load.image("llanura", configApp.getParams().PLAY_URL+'game/palabra/assets/tanda1/llanura.jpg');
        this.load.image("meandro",configApp.getParams().PLAY_URL+ 'game/palabra/assets/tanda1/meandro.png');
        this.load.image("anguila", configApp.getParams().PLAY_URL+'game/palabra/assets/tanda1/anguila.png');
        this.load.image("gusarapo", configApp.getParams().PLAY_URL+'game/palabra/assets/tanda1/gusarapo.png');
        // Tanda 2
        this.load.image("agua", configApp.getParams().PLAY_URL+'game/palabra/assets/tanda2/Agua.jpg');
        this.load.image("contaminacion", configApp.getParams().PLAY_URL+'game/palabra/assets/tanda2/Contaminacion.jpg');
        this.load.image("fuente", configApp.getParams().PLAY_URL+'game/palabra/assets/tanda2/Fuente.jpg');
        this.load.image("helecho", configApp.getParams().PLAY_URL+'game/palabra/assets/tanda2/Helecho.jpg');
        this.load.image("lecho", configApp.getParams().PLAY_URL+'game/palabra/assets/tanda2/Lecho.jpg');
        this.load.image("niebla", configApp.getParams().PLAY_URL+'game/palabra/assets/tanda2/Niebla.jpg');
        this.load.image("presa", configApp.getParams().PLAY_URL+'game/palabra/assets/tanda2/Presa.jpg');
        this.load.image("rio", configApp.getParams().PLAY_URL+'game/palabra/assets/tanda2/Rio.png');
        this.load.image("salmon", configApp.getParams().PLAY_URL+'game/palabra/assets/tanda2/Salmon.jpg');
        this.load.image("ciclo", configApp.getParams().PLAY_URL+'game/palabra/assets/tanda2/ciclo.png');
        // Tanda 3
        this.load.image("agricultura", configApp.getParams().PLAY_URL+'game/palabra/assets/tanda3/agricultura.png');
        this.load.image("cambio", configApp.getParams().PLAY_URL+'game/palabra/assets/tanda3/cambio_climatico.jpg');
        this.load.image("erosion", configApp.getParams().PLAY_URL+'game/palabra/assets/tanda3/erosion.jpg');
        this.load.image("incendios", configApp.getParams().PLAY_URL+'game/palabra/assets/tanda3/incendios.jpg');
        this.load.image("invasoras", configApp.getParams().PLAY_URL+'game/palabra/assets/tanda3/invasoras.jpg');
        this.load.image("sauce", configApp.getParams().PLAY_URL+'game/palabra/assets/tanda3/sauce_blanco.jpg');
        this.load.image("tramoBajo", configApp.getParams().PLAY_URL+'game/palabra/assets/tanda3/tramo.jpg');
        this.load.image("triton", configApp.getParams().PLAY_URL+'game/palabra/assets/tanda3/triton.jpg');
        this.load.image("lluvia", configApp.getParams().PLAY_URL+'game/palabra/assets/tanda3/lluvia.png');
        this.load.image("lamprea", configApp.getParams().PLAY_URL+'game/palabra/assets/tanda3/lamprea.jpg');
        // Tanda 4
        this.load.image("cantabrico", configApp.getParams().PLAY_URL+'game/palabra/assets/tanda4/cantabrico.jpg');
        this.load.image("inundacion",configApp.getParams().PLAY_URL+ 'game/palabra/assets/tanda4/inundacion.png');
        this.load.image("confe", configApp.getParams().PLAY_URL+'game/palabra/assets/tanda4/confe.png');
        this.load.image("hidro", configApp.getParams().PLAY_URL+'game/palabra/assets/tanda4/hidraulica.png');
        this.load.image("martin", configApp.getParams().PLAY_URL+'game/palabra/assets/tanda4/martin_pescador.jpg');
        this.load.image("nieve", configApp.getParams().PLAY_URL+'game/palabra/assets/tanda4/nieve.jpg');
        this.load.image("vertido", configApp.getParams().PLAY_URL+'game/palabra/assets/tanda4/vertido.png');
        this.load.image("nutria", configApp.getParams().PLAY_URL+'game/palabra/assets/tanda4/nutria.jpg');
        this.load.image("sedimentos", configApp.getParams().PLAY_URL+'game/palabra/assets/tanda4/sedimentos.png');
        this.load.image("evaporacion", configApp.getParams().PLAY_URL+'game/palabra/assets/tanda4/evaporacion.jpg');
    }

    /**
     * Método para dar inicio al juego después de cargar los elementos 
     */
    create ()
    {
        this.scene.start("startScene");
    }
}
