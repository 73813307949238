import Phaser from "phaser";
import configApp from '../../../../configApp.js';
/**
 * Clase para cargar los elementos del juego
 * @author Mehdi Lahbis Mhamdi
 * @class
 */
export class Preloader extends Phaser.Scene {
    constructor() {
        super({
            key: 'Preloader'
        });
    }

    preload() {
        // fondo
        this.load.image('bg', configApp.getParams().PLAY_URL+'game/explora_tu_rio/assets/bg.png');
        // botón start
        this.load.image('startbtn', configApp.getParams().PLAY_URL+"game/explora_tu_rio/assets/btnStart.png");
        // icono puzzle y linea
        this.load.image('icon', configApp.getParams().PLAY_URL+"game/explora_tu_rio/assets/icono.png");
        this.load.image('line', configApp.getParams().PLAY_URL+"game/explora_tu_rio/assets/linea.png");
        // gota
        this.load.image('drop', configApp.getParams().PLAY_URL+"game/explora_tu_rio/assets/waterDrop.png");
        // textBg
        this.load.image('textBg', configApp.getParams().PLAY_URL+"game/explora_tu_rio/assets/textBg.png");
        // agente chico
        this.load.image('manAgent', configApp.getParams().PLAY_URL+"game/explora_tu_rio/assets/manAgent.png");
        // agente chica
        this.load.image('womanAgent', configApp.getParams().PLAY_URL+"game/explora_tu_rio/assets/womanAgent.png");
        // ok
        this.load.image('ok', configApp.getParams().PLAY_URL+"game/explora_tu_rio/assets/ok.png");
        // wrong answer
        this.load.image('x', configApp.getParams().PLAY_URL+"game/explora_tu_rio/assets/x.png");
        // close
        this.load.image('close', configApp.getParams().PLAY_URL+"game/explora_tu_rio/assets/close.png");
        // icono volver a intentar
        this.load.image('restart', configApp.getParams().PLAY_URL+"game/explora_tu_rio/assets/tryAgainBtn.png");
        // cuadro y boton next
        this.load.image('nextbtn', configApp.getParams().PLAY_URL+"game/explora_tu_rio/assets/btnNext.png");
        this.load.image("next", configApp.getParams().PLAY_URL+"game/explora_tu_rio/assets/cuadroNext.png");
        // imagenes nivel 1
        // pantalla 1
        this.load.image("image1-1", configApp.getParams().PLAY_URL+"game/explora_tu_rio/assets/Nivel1/Pantalla 1/2.png");
        this.load.image("image1-2", configApp.getParams().PLAY_URL+"game/explora_tu_rio/assets/Nivel1/Pantalla 1/3.png");
        this.load.image("image1-3", configApp.getParams().PLAY_URL+"game/explora_tu_rio/assets/Nivel1/Pantalla 1/7.png");
        this.load.image("image1-4", configApp.getParams().PLAY_URL+"game/explora_tu_rio/assets/Nivel1/Pantalla 1/8.png");
        this.load.image("image1-5", configApp.getParams().PLAY_URL+"game/explora_tu_rio/assets/Nivel1/Pantalla 1/11.png");
        this.load.image("image1-6", configApp.getParams().PLAY_URL+"game/explora_tu_rio/assets/Nivel1/Pantalla 1/15.png");
        // pantalla 2
        this.load.image("image2-1", configApp.getParams().PLAY_URL+"game/explora_tu_rio/assets/Nivel1/Pantalla 2/1.png");
        this.load.image("image2-2", configApp.getParams().PLAY_URL+"game/explora_tu_rio/assets/Nivel1/Pantalla 2/5.png");
        this.load.image("image2-3", configApp.getParams().PLAY_URL+"game/explora_tu_rio/assets/Nivel1/Pantalla 2/11.png");
        this.load.image("image2-4", configApp.getParams().PLAY_URL+"game/explora_tu_rio/assets/Nivel1/Pantalla 2/8.png");
        this.load.image("image2-5", configApp.getParams().PLAY_URL+"game/explora_tu_rio/assets/Nivel1/Pantalla 2/12.png");
        this.load.image("image2-6", configApp.getParams().PLAY_URL+"game/explora_tu_rio/assets/Nivel1/Pantalla 2/15.png");
        // pantalla 3
        this.load.image("image3-1", configApp.getParams().PLAY_URL+"game/explora_tu_rio/assets/Nivel1/Pantalla 3/1.png");
        this.load.image("image3-2", configApp.getParams().PLAY_URL+"game/explora_tu_rio/assets/Nivel1/Pantalla 3/2.png");
        this.load.image("image3-3", configApp.getParams().PLAY_URL+"game/explora_tu_rio/assets/Nivel1/Pantalla 3/3.png");
        this.load.image("image3-4", configApp.getParams().PLAY_URL+"game/explora_tu_rio/assets/Nivel1/Pantalla 3/5.png");
        this.load.image("image3-5", configApp.getParams().PLAY_URL+"game/explora_tu_rio/assets/Nivel1/Pantalla 3/6.png");
        this.load.image("image3-6", configApp.getParams().PLAY_URL+"game/explora_tu_rio/assets/Nivel1/Pantalla 3/11.png");
        // pantalla 3
        this.load.image("image1-4-1", configApp.getParams().PLAY_URL+"game/explora_tu_rio/assets/Nivel1/Pantalla 4/1.png");
        this.load.image("image1-4-2", configApp.getParams().PLAY_URL+"game/explora_tu_rio/assets/Nivel1/Pantalla 4/5.png");
        this.load.image("image1-4-3", configApp.getParams().PLAY_URL+"game/explora_tu_rio/assets/Nivel1/Pantalla 4/6.png");
        this.load.image("image1-4-4", configApp.getParams().PLAY_URL+"game/explora_tu_rio/assets/Nivel1/Pantalla 4/12.png");
        this.load.image("image1-4-5", configApp.getParams().PLAY_URL+"game/explora_tu_rio/assets/Nivel1/Pantalla 4/15.png");
        this.load.image("image1-4-6", configApp.getParams().PLAY_URL+"game/explora_tu_rio/assets/Nivel1/Pantalla 4/28.png");
        // imagenes nivel 2
        // pantalla 4
        this.load.image("image4-1", configApp.getParams().PLAY_URL+"game/explora_tu_rio/assets/Nivel2/Pantalla 4/3.png");
        this.load.image("image4-2", configApp.getParams().PLAY_URL+"game/explora_tu_rio/assets/Nivel2/Pantalla 4/7.png");
        this.load.image("image4-3", configApp.getParams().PLAY_URL+"game/explora_tu_rio/assets/Nivel2/Pantalla 4/8.png");
        this.load.image("image4-4", configApp.getParams().PLAY_URL+"game/explora_tu_rio/assets/Nivel2/Pantalla 4/10.png");
        this.load.image("image4-5", configApp.getParams().PLAY_URL+"game/explora_tu_rio/assets/Nivel2/Pantalla 4/15.png");
        this.load.image("image4-6", configApp.getParams().PLAY_URL+"game/explora_tu_rio/assets/Nivel2/Pantalla 4/11.png");
        // pantalla 5
        this.load.image("image5-1", configApp.getParams().PLAY_URL+"game/explora_tu_rio/assets/Nivel2/Pantalla 5/4.png");
        this.load.image("image5-2", configApp.getParams().PLAY_URL+"game/explora_tu_rio/assets/Nivel2/Pantalla 5/5.png");
        this.load.image("image5-3", configApp.getParams().PLAY_URL+"game/explora_tu_rio/assets/Nivel2/Pantalla 5/9.png");
        this.load.image("image5-4", configApp.getParams().PLAY_URL+"game/explora_tu_rio/assets/Nivel2/Pantalla 5/12.png");
        this.load.image("image5-5", configApp.getParams().PLAY_URL+"game/explora_tu_rio/assets/Nivel2/Pantalla 5/13.png");
        this.load.image("image5-6", configApp.getParams().PLAY_URL+"game/explora_tu_rio/assets/Nivel2/Pantalla 5/14.png");
        // pantalla 6
        this.load.image("image6-1", configApp.getParams().PLAY_URL+"game/explora_tu_rio/assets/Nivel2/Pantalla 6/1.png");
        this.load.image("image6-2", configApp.getParams().PLAY_URL+"game/explora_tu_rio/assets/Nivel2/Pantalla 6/2.png");
        this.load.image("image6-3", configApp.getParams().PLAY_URL+"game/explora_tu_rio/assets/Nivel2/Pantalla 6/3.png");
        this.load.image("image6-4", configApp.getParams().PLAY_URL+"game/explora_tu_rio/assets/Nivel2/Pantalla 6/4.png");
        this.load.image("image6-5", configApp.getParams().PLAY_URL+"game/explora_tu_rio/assets/Nivel2/Pantalla 6/9.png");
        this.load.image("image6-6", configApp.getParams().PLAY_URL+"game/explora_tu_rio/assets/Nivel2/Pantalla 6/13.png");
        // pantalla 7
        this.load.image("image7-1", configApp.getParams().PLAY_URL+"game/explora_tu_rio/assets/Nivel2/Pantalla 7/2.png");
        this.load.image("image7-2", configApp.getParams().PLAY_URL+"game/explora_tu_rio/assets/Nivel2/Pantalla 7/5.png");
        this.load.image("image7-3", configApp.getParams().PLAY_URL+"game/explora_tu_rio/assets/Nivel2/Pantalla 7/6.png");
        this.load.image("image7-4", configApp.getParams().PLAY_URL+"game/explora_tu_rio/assets/Nivel2/Pantalla 7/10.png");
        this.load.image("image7-5", configApp.getParams().PLAY_URL+"game/explora_tu_rio/assets/Nivel2/Pantalla 7/12.png");
        this.load.image("image7-6", configApp.getParams().PLAY_URL+"game/explora_tu_rio/assets/Nivel2/Pantalla 7/14.png");
        // imagenes nivel 3
        // pantalla 8
        this.load.image("image8-1", configApp.getParams().PLAY_URL+"game/explora_tu_rio/assets/Nivel3/Pantalla 8/4.png");
        this.load.image("image8-2", configApp.getParams().PLAY_URL+"game/explora_tu_rio/assets/Nivel3/Pantalla 8/5.png");
        this.load.image("image8-3", configApp.getParams().PLAY_URL+"game/explora_tu_rio/assets/Nivel3/Pantalla 8/7.png");
        this.load.image("image8-4", configApp.getParams().PLAY_URL+"game/explora_tu_rio/assets/Nivel3/Pantalla 8/9.png");
        this.load.image("image8-5", configApp.getParams().PLAY_URL+"game/explora_tu_rio/assets/Nivel3/Pantalla 8/12.png");
        this.load.image("image8-6", configApp.getParams().PLAY_URL+"game/explora_tu_rio/assets/Nivel3/Pantalla 8/13.png");
        // pantalla 9
        this.load.image("image9-1", configApp.getParams().PLAY_URL+"game/explora_tu_rio/assets/Nivel3/Pantalla 9/16.png");
        this.load.image("image9-2", configApp.getParams().PLAY_URL+"game/explora_tu_rio/assets/Nivel3/Pantalla 9/17.png");
        this.load.image("image9-3", configApp.getParams().PLAY_URL+"game/explora_tu_rio/assets/Nivel3/Pantalla 9/18.png");
        this.load.image("image9-4", configApp.getParams().PLAY_URL+"game/explora_tu_rio/assets/Nivel3/Pantalla 9/19.png");
        this.load.image("image9-5", configApp.getParams().PLAY_URL+"game/explora_tu_rio/assets/Nivel3/Pantalla 9/20.png");
        this.load.image("image9-6", configApp.getParams().PLAY_URL+"game/explora_tu_rio/assets/Nivel3/Pantalla 9/21.png");
        // pantalla 10
        this.load.image("image10-1", configApp.getParams().PLAY_URL+"game/explora_tu_rio/assets/Nivel3/Pantalla 10/22.png");
        this.load.image("image10-2", configApp.getParams().PLAY_URL+"game/explora_tu_rio/assets/Nivel3/Pantalla 10/23.png");
        this.load.image("image10-3", configApp.getParams().PLAY_URL+"game/explora_tu_rio/assets/Nivel3/Pantalla 10/24.png");
        this.load.image("image10-4", configApp.getParams().PLAY_URL+"game/explora_tu_rio/assets/Nivel3/Pantalla 10/25.png");
        this.load.image("image10-5", configApp.getParams().PLAY_URL+"game/explora_tu_rio/assets/Nivel3/Pantalla 10/26.png");
        this.load.image("image10-6", configApp.getParams().PLAY_URL+"game/explora_tu_rio/assets/Nivel3/Pantalla 10/27.png");
        // pantalla 11
        this.load.image("image11-1", configApp.getParams().PLAY_URL+"game/explora_tu_rio/assets/Nivel3/Pantalla 11/1.png");
        this.load.image("image11-2", configApp.getParams().PLAY_URL+"game/explora_tu_rio/assets/Nivel3/Pantalla 11/3.png");
        this.load.image("image11-3", configApp.getParams().PLAY_URL+"game/explora_tu_rio/assets/Nivel3/Pantalla 11/6.png");
        this.load.image("image11-4", configApp.getParams().PLAY_URL+"game/explora_tu_rio/assets/Nivel3/Pantalla 11/9.png");
        this.load.image("image11-5", configApp.getParams().PLAY_URL+"game/explora_tu_rio/assets/Nivel3/Pantalla 11/12.png");
        this.load.image("image11-6", configApp.getParams().PLAY_URL+"game/explora_tu_rio/assets/Nivel3/Pantalla 11/15.png");
    }

    /**
    * Método para dar inicio al juego después de cargar los elementos 
    */
    create() {
        this.scene.start("startScene");
    }
}