import React from "react";

import { Modal, Button, Row, Col } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";

//Para poder utilizar 'this.props.history.push()' en un componente
import { withRouter } from 'react-router-dom';
import { inject, observer } from "mobx-react";

import shopIcon from '../assets/images/buttons/tienda.png';
import trophyIcon from '../assets/images/buttons/ranking.png';
import disabledIcon from '../assets/icons/disabledIcon.png';
import checkIcon from '../assets/icons/check.png';

import GlobalFonts from "../assets/fonts/fonts";

import StepsModal from '../components/StepsModal.js';

import './LevelsModal.scss';
import './ManualModal.scss';

import { reaction } from "mobx";
import { List } from "antd";
import { Empty } from "antd";

class LevelsModal extends React.Component {

    //*********************************************************
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            showStepsModal: false,
            level: 0,
            title: '',
            icon: null,
        };

        this.reactions = [];
    }

    //*********************************************************
    componentDidMount() {
        this.handleLoadLevels();

        this.reactions.push(
            reaction(
                () => this.props.show,
                () => {
                    if (this.props.show == true) {
                        this.setState({ show: this.props.show })
                    }
                }
            )
        )
    }

    //*********************************************************
    handleLoadLevels() {
        this.props.teamsStore.doActionGetLevelsList();
    }

    //*********************************************************
    showStepsModal = () => {
        this.setState({
            show: false,
            showStepsModal: true
        })
    }

    //*********************************************************
    render() {
        const levels = this.props.teamsStore.levels == [] ? [] : this.props.teamsStore.levels;

        const originalSteps = this.props.teamsStore.steps == [] ? [] : this.props.teamsStore.steps;

        const lastStep = originalSteps.find((item, index) => index == originalSteps.length - 1);

        const userLogged = this.props.user;

        const userLoggedTeam = this.props.userTeam;

        let nextStep = originalSteps.find(item => item.order == this.props.userTeam?.step.order + 1);

        let nextLevel = levels.find(item => item.id == nextStep?.level.id);

        levels.map((item) => {
            if (item != nextLevel && item?.num > nextLevel?.num) {
                item.disabled = true;
            }
        });

        return (
            <>
                <GlobalFonts />
                {!this.props.endOfGame &&
                    <Modal
                        className="levelsmodal"
                        title={
                            <Row justify='center' align="top">
                                <Col id="icon" sm={{ span: 4 }} md={{ span: 4 }}>
                                    {<img className="modal-icon" src={shopIcon} />}
                                </Col>
                                <Col className="title-background">
                                    <div className="title">
                                        <p>TIENDA</p>
                                    </div>
                                </Col>
                                <Col className="subtitle-background">
                                    <div className="subtitle">
                                        <p>ECOSISTEMA FLUVIAL</p>
                                    </div>
                                </Col>
                            </Row>
                        }
                        closeIcon=
                        {
                            <Button onClick={this.props.close} shape="circle" id="close-button" >
                                <CloseCircleOutlined className="close-circle" />
                            </Button>
                        }
                        open={this.props.show}
                        width="max-content"
                        closable={true}
                        maskClosable={true}
                        footer={null}
                    >
                        <div /* style={{ backgroundColor: '#0e3e41', textAlign: 'center' }} */>
                            <List locale={{ emptyText: (<Empty description="No hay resultados" image={Empty.PRESENTED_IMAGE_SIMPLE} />) }}
                                dataSource={levels}
                                renderItem={item => (
                                    <>
                                        <List.Item onClick={() => this.setState({ showStepsModal: true, level: item })}>
                                            <Row className={`list-item box-screen-mode`}>
                                                <Col className="data" md={6}>
                                                    <div className={`level ${item.disabled ? 'disabled' : item.num < nextLevel?.num ? 'completed' : ''}`}>
                                                        <p className="title">{item.title}</p>
                                                        <p className="num">NIVEL {item.num}</p>
                                                        <img className="icon" src={require(`../assets/icons/iconoNivel${item.num}.png`)} />
                                                        {item.disabled &&
                                                            <img className="disabled-icon" src={disabledIcon} />
                                                        }
                                                        {!item.disabled && item.num < nextLevel?.num &&
                                                            <img className="disabled-icon" src={checkIcon} />
                                                        }
                                                    </div>
                                                </Col>
                                            </Row>
                                        </List.Item>
                                    </>
                                )}
                            >
                            </List>
                            <StepsModal show={this.state.showStepsModal} close={() => this.setState({ showStepsModal: false })} user={userLogged} team={userLoggedTeam} level={this.state.level} />
                        </div>
                    </Modal>}
                {this.props.endOfGame &&
                    <Modal
                        className="manualmodal"
                        title={
                            <Row justify='center' align="top">
                                <Col id="icon" sm={{ span: 4 }} md={{ span: 4 }}>
                                    {<img className="modal-icon" src={trophyIcon} />}
                                </Col>
                                <Col className="title-background">
                                    <div className="title">
                                        <p>FIN DEL JUEGO</p>
                                    </div>
                                </Col>
                            </Row>
                        }
                        closeIcon=
                        {
                            <Button onClick={this.props.close} shape="circle" id="close-button" >
                                <CloseCircleOutlined className="close-circle" />
                            </Button>
                        }
                        open={this.props.show}
                        width="max-content"
                        closable={true}
                        maskClosable={true}
                        footer={null}
                    >
                        <div className="content-manual">
                            <p className="message">
                                ¡Felicidades! Habéis completado el juego y demostrado vuestras habilidades. Pero la aventura del aprendizaje nunca termina. ¡Seguid explorando y descubriendo nuevas cosas cada día!
                            </p>
                        </div>
                        <div>
                            <div style={{ backgroundColor: '#0e3e41', textAlign: 'center' }}>
                                <Row justify="center" align="top">
                                    <Col id="icon" sm={{ span: 12 }} md={{ span: 12 }}>
                                        <img src={shopIcon} className="options-icon" alt="Memorik"
                                            onClick={() => console.log("SHOP")} />
                                        <p className="games-title"> Reanudar </p>
                                    </Col>
                                    <Col id="icon" sm={{ span: 12 }} md={{ span: 12 }}>
                                        <img src={trophyIcon} className="options-icon" alt="Palabra Revuelta"
                                            onClick={() => console.log("RANKING")} />
                                        <p className="games-title"> Ranking </p>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Modal>
                }
            </>
        )
    }
}

export default withRouter(inject('teamsStore')(observer(LevelsModal)));