import { makeAutoObservable, flow } from "mobx";
import { makePersistable } from 'mobx-persist-store';

import * as Actions from './actionFlags.js';

import generalStore from "./generalStore.js";

import {
    getPaginatedListOfUsers, saveUserEditService, removeUserService, changedUserDataService, sendUserByEmailService, registerPlayerService,
    changePasswordService, resetPasswordService, changeEnabledUserStateService, getUserDataService, savePlayerScoreService, getTeamPlayersByTeam
} from '../services/UserService.js';

import { callServiceEventAware } from '.';
import AuthenticationService from "../services/AuthenticationService.js";
import { savePlayerEditService } from "../services/PlayerService.js";


class usersStore {
    userLogged = {};
    userLoggedOrganization = {};
    loggedIn = false;
    sentUserEmailFlag = false;
    sentUserActivationFlag = false;
    changedUserDataFlag = false;
    changedPasswordFlag = false;
    changeUsersFilter = false;
    managerUser = {};
    users = {};
    usersFilter = {};
    usersOrderBy = "surname_ASC";
    resetUsersList = false;
    message = "";
    error = "";
    newEmail = "";

    //*********************************************************
    constructor() {
        makeAutoObservable(this, {}, { autobind: true });

        makePersistable(this, { name: 'userStore', properties: ['userLogged', 'loggedIn', 'users', 'usersFilter', 'usersOrderBy'], storage: window.sessionStorage });
    }

    //*********************************************************
    doActionRemoveMessage = flow(function* () {
        this.message = "";
    });

    //*********************************************************
    doActionRememberMe = flow(function* (token) {

        try {
            this.loggedIn = false;

            const response = yield callServiceEventAware(Actions.LOGIN, AuthenticationService.rememberMe, token);

            this.loggedIn = true;
            this.userLogged = response.data.user;

            AuthenticationService.registerSuccessfulLoginForJwt(response.data.user.username, response.data.token, response.data.refreshToken);

        } catch (error) {
            generalStore.doActionEndService(Actions.LOGIN);

            if (error.response != null && error.response.status == 401) {
                generalStore.doActionGeneralError("Usuario o contraseña incorrecta");
            }
            else {
                console.log("ERROR EN LLAMADA AL API EN usersStore.js - doActionRememberMe: " + error.message);
                console.log(error);
                generalStore.doActionGeneralError("Error accediendo al servidor: " + error.message);
            }
        }
    });


    //*********************************************************
    doActionPlayerLogin = flow(function* (username, password, rememberMe) {
        const location = window.location.href;        
        const isManager = location.includes('#') ? true : false;        
        try {
            this.loggedIn = false;

            const response = yield callServiceEventAware(Actions.LOGIN_PLAYER, AuthenticationService.executeJwtAuthenticationService, username, password, rememberMe);
            
            if (response.data.user.role.name!="PLAYER" && !isManager) {
                throw new Error('Desde este formulario solamente pueden acceder los jugadores individuales');
            }
            else {
                this.loggedIn = true;
                this.userLogged = response.data.user;
                AuthenticationService.registerSuccessfulLoginForJwt(username, response.data.token, response.data.refreshToken);
            }
            
        } catch (error) {
            generalStore.doActionEndService(Actions.LOGIN_PLAYER);
            if (error.response != null && error.response.status == 401) {
                generalStore.doActionGeneralError("Usuario o contraseña incorrectos");
            }
            else if (error.response != null && error.response.status == 422) {
                let code = error.response.data.message.split(": ")[1];
                if (error.response.data != undefined && code == 101)
                    generalStore.doActionGeneralError("Su cuenta está invalidada, revise su correo electrónico donde tiene un email de activación. Si no lo visualiza, puede volver a recibirlo accediendo a '¿Has olvidado tu contraseña?'");
                else if (error.response.data != undefined && code == 102)
                    generalStore.doActionGeneralError("Su cuenta está desactivada. Contacta con un administrador para solicitar su activación.");
                else
                    generalStore.doActionGeneralError("Error accediendo al servidor: 422");
            }
            else {
                console.log("ERROR EN LLAMADA AL API EN usersStore.js - doActionPlayerLogin: " + error.message);
                console.log(error);
                generalStore.doActionGeneralError("Error accediendo al servidor: " + error.message);
            }
        }
    });

    //*********************************************************
    doActionTeamPlayerLogin = flow(function* (username, password, idOrganization, idTeam) {
        try {
            this.loggedIn = false;

            const response = yield callServiceEventAware(Actions.LOGIN_TEAM_PLAYER, AuthenticationService.executeJwtAuthenticationForTeamPlayersService, username, password, idOrganization, idTeam);

            this.loggedIn = true;

            this.userLogged = response.data.user;

            this.userLogged.token = response.data.token;

            this.userLoggedOrganization = this.userLogged.organization;

            AuthenticationService.registerSuccessfulLoginForJwt(username, response.data.token, response.data.refreshToken);
                     
        } catch (error) {                     
            
            generalStore.doActionEndService(Actions.LOGIN_TEAM_PLAYER);
            if (error.response != null && error.response.status == 401) {
                generalStore.doActionGeneralError("Datos incorrectos");
            }
            else if (error.response != null && error.response.status == 422) {              
                if ( error.response.status == 422)
                    generalStore.doActionGeneralError("La cuenta está desactivada. Contacta con un administrador para solicitar su activación.");                
            }
            else {
                console.log("ERROR EN LLAMADA AL API EN usersStore.js - doActionTeamPlayerLogin: " + error.message);
                console.log(error);
                generalStore.doActionGeneralError("Error accediendo al servidor: " + error.message);
            }
        }
    });

    //*********************************************************
    doActionChangeEnabledUserState = flow(function* (user, disabled) {
        try {
            changeEnabledUserStateService(user, disabled);

            const status = disabled ? "desactivado/a" : "activado/a";

            if (this.users.list == undefined)
                this.message = "El/La usuario/a ha sido " + status;
            else {
                let modifiedUser = this.users.list.filter(item => item.id == user.id)[0];
                modifiedUser.disabled = disabled;

                let currentUsers = [modifiedUser, ...this.users.list.filter(item => item.id != user.id)];

                // Reordenar la lista porque por defecto insertamos el item modificado al final
                currentUsers.sort(this.sortUsers(this.usersOrderBy));

                this.message = "El/La usuario/a ha sido " + status;
                this.users = { list: currentUsers };
            }

        } catch (error) {
            console.log("ERROR EN LLAMADA AL API EN usersStore.js - doActionChangeEnabledUserState: " + error.message);
            console.log(error);
            generalStore.doActionGeneralError("Error accediendo al servidor: " + error.message);
        }
    });

    //*********************************************************
    doActionRemindUser = flow(function* (email) {
        try {
            this.sentUserEmailFlag = false;

            yield callServiceEventAware(Actions.REMINDUSER, sendUserByEmailService, email);

            this.sentUserEmailFlag = true;

        } catch (error) {
            generalStore.doActionEndService(Actions.REMINDUSER);
            console.log("ERROR EN LLAMADA AL API EN usersStore.js - doActionRemindUser: " + error.message);
            console.log(error);

            if (error.response != null) {
                if (error.response.status == 404) {
                    generalStore.doActionGeneralError("No existe ningún usuario con ese correo electrónico");
                }
                else if (error.response.status == 503) {
                    generalStore.doActionGeneralError("No es posible conectar con el servidor de correo. Inténtelo más tarde.");
                }
                else if (error.response.status == 422) {
                    if (error.response.data != undefined && error.response.data.code != undefined && error.response.data.code == 102)
                        generalStore.doActionGeneralError("El usuario está desactivado. Es necesario contactar con un administrador para activarlo.");
                    else
                        generalStore.doActionGeneralError("Error accediendo al servidor: " + error.message);
                }
                else
                    generalStore.doActionGeneralError("Error accediendo al servidor: " + error.message);

            } else {
                generalStore.doActionGeneralError("Error accediendo al servidor: " + error.message);
            }
        }
    });

    //*********************************************************
    doActionRegisterUser = flow(function* (data) {
        try {
            this.sentUserActivationFlag = false;
            yield callServiceEventAware(Actions.REGISTER_USER, registerPlayerService, data);
            this.sentUserActivationFlag = true;            

        } catch (error) {         
           
            generalStore.doActionEndService(Actions.REGISTER_USER);
            console.log("ERROR EN LLAMADA AL API EN usersStore.js - doActionRegisterUser: " + error.message);           
            console.log("ERROR EN LLAMADA AL API EN usersStore.js - error: " + error);           

            if (error.response != null) {    
                console.log("error.response" , error.response);               
                
                if (error.response.status == 503) {
                    generalStore.doActionGeneralError("En este momento no está disponible el envío de correos de validación. Intente registrarse dentro de unos minutos. ");                    
                }
                
                else if (error.response.status == 422) {                    
                    let code = error.response.data.message.split(": ")[1];
                    if (error.response.data != undefined && code == 202){                    
                        generalStore.doActionGeneralError("Ya existe un usuario con ese mismo nick");                        
                    }
                    else if (error.response.data != undefined && error.response.data.code != undefined){
                        generalStore.doActionGeneralError("Error al guardar los datos");                        
                    }else{
                        generalStore.doActionGeneralError("Ya existe un usuario con ese email");                      

                    }
                }
                else {
                    generalStore.doActionGeneralError("Error accediendo al servidor: " + error.message);
                }
            }
            else generalStore.doActionGeneralError("Se ha producido un error: " + error.message);
        }
    })

    //*********************************************************
    doActionChangeUserData = flow(function* (data) {
        try {
            let email = this.userLogged.email;
            let email2 = data.email;
            let emailChange = email != email2;

            this.changedUserDataFlag = false;

            const response = yield callServiceEventAware(Actions.CHANGE_USER_DATA, changedUserDataService, data);
            const user = response.data;
            if (emailChange) {
                user.email = email;
                this.newEmail = email2;
            } else {
                this.newEmail = "";
            }

            if (this.users.list == undefined) {
                this.changedUserDataFlag = true;
                this.userLogged = user;
            }
            else {
                let currentUsers = [user, ...this.users.list.filter(item => item.id != user.id)];

                currentUsers.sort(this.sortUsers(this.usersOrderBy));

                this.userLogged = user;
                this.changedUserDataFlag = true;
                this.users = currentUsers;
            }

        } catch (error) {
            generalStore.doActionEndService(Actions.CHANGE_USER_DATA);
            console.log("ERROR EN LLAMADA AL API EN usersStore.js - doActionChangeUserData: " + error.message);
            console.log(error);

            if (error.response != null) {
                if (error.response.status == 503) {
                    generalStore.doActionGeneralError("En este momento no está disponible el envío de correos de validación.  No se ha podido actualizar los datos.");
                }
                else if (error.response.status == 422) {
                    if (error.response.data != undefined && error.response.data.code != undefined)
                        generalStore.doActionGeneralError("Ya existe un usuario con ese email");
                    else
                        generalStore.doActionGeneralError("Error al guardar los datos");
                }
                else {
                    generalStore.doActionGeneralError("Se ha producido un error: " + error.message);
                }
            }
            else generalStore.doActionGeneralError("Se ha producido un error: " + error.message);
        }
    });

    //*********************************************************
    doActionChangePassword = flow(function* (token, password) {
        try {
            this.changedPasswordFlag = false;
            this.loggedIn = false;
            const response = yield callServiceEventAware(Actions.CHANGE_PASSWORD, changePasswordService, token, password);
            this.changedPasswordFlag = true;
            this.loggedIn = true;
            this.userLogged = response.data.user;

            AuthenticationService.registerSuccessfulLoginForJwt(response.data.username, response.data.token, response.data.refreshToken);

        } catch (error) {
            console.log("ERROR EN LLAMADA AL API EN usersStore.js - doActionChangePassword: " + error.message);
            console.log(error);
            this.loggedIn = false;
            if (error.response != null) {
                if (error.response.status == 401) {
                    this.error = "El token suministrado no existe o ha caducado";
                } else {
                    this.error = "Se ha producido un error: " + error.message;
                }
            }
            else
                this.error = "Se ha producido un error: " + error.message;
            generalStore.doActionEndService(Actions.RESET_PASSWORD);

        }
    });

    //*********************************************************
    doActionResetPassword = flow(function* (password) {
        try {

            this.changedPasswordFlag = false;
            this.loggedIn = false;
            const response = yield callServiceEventAware(Actions.RESET_PASSWORD, resetPasswordService, password);
            this.changedPasswordFlag = true;
            this.loggedIn = true;
            this.userLogged = response.data;
            //AuthenticationService.registerSuccessfulLoginForJwt(response.data.username, response.data.token, response.data.refreshToken);

        } catch (error) {
            console.log("ERROR EN LLAMADA AL API EN usersStore.js - doActionResetPassword: " + error.message);
            console.log(error);

            if (error.response != null) {
                if (error.response.status == 401) {
                    generalStore.doActionGeneralError("El token suministrado no existe o ha caducado");
                } else {
                    generalStore.doActionGeneralError("Se ha producido un error: " + error.message);
                }
            }
            else generalStore.doActionGeneralError("Se ha producido un error: " + error.message);
            generalStore.doActionEndService(Actions.RESET_PASSWORD);
        }

    })

    //*********************************************************
    doActionLogout = flow(function* () {
        try {
            AuthenticationService.logout();
            this.userLogged = {};
            this.loggedIn = false;
            this.sentUserEmailFlag = false;
            this.sentUserActivationFlag = false;
            this.changedUserDataFlag = false;
            this.changedPasswordFlag = false;
            this.message = "";
            this.newEmail = "";
            this.error = "";
        } catch (error) {
            console.log(error)
            generalStore.doActionGeneralError("Error accediendo al servicio: " + error.message);
        }
    });

    //*********************************************************
    doActionAuthenticationByToken = flow(function* (token) {
        try {
            this.loggedIn = false;
            const response = yield callServiceEventAware(Actions.LOGIN, AuthenticationService.executeJwtAuthenticationByTokenService, token);
            this.loggedIn = true;
            this.userLogged = response.data.user;
            AuthenticationService.registerSuccessfulLoginForJwt(response.data.username, response.data.token, response.data.refreshToken);


        } catch (error) {
            generalStore.doActionEndService(Actions.LOGIN);

            console.log("ERROR EN LLAMADA AL API EN usersStore.js - doActionAuthenticationByToken: " + error.message);
            console.log(error);
            if (error.response != null) {
                if (error.response.status == 401) {
                    this.error = "El token suministrado no existe o ha caducado";
                } else {
                    this.error = "Se ha producido un error: " + error.message;
                }
            }
            else
                this.error = "Se ha producido un error: " + error.message;
        }
    })

    //*********************************************************
    doActionGetPaginatedListOfUsers = flow(function* (resetList, filter, page) {

        try {
            const response = yield callServiceEventAware(Actions.GET_PAGINATED_LIST_OF_USERS, getPaginatedListOfUsers, filter, page, this.usersOrderBy);

            const users = response.data;

            let currentUsers;
            let hasMore = true;

            if (page > 0) {
                currentUsers = this.users.list;

                if (users.length > 0) {
                    currentUsers = currentUsers.concat(users);
                }
                else {
                    hasMore = false;
                }
            }
            else
                currentUsers = users;

            this.resetUsersList = resetList;
            this.usersFilter = filter;
            this.users = { hasMore: hasMore, page: page, list: currentUsers }


        } catch (error) {
            console.log("ERROR EN LLAMADA AL API EN usersStore.js - doActionGetPaginatedListOfUsers: " + error.message);
            console.log(error);
            generalStore.doActionEndService(Actions.GET_PAGINATED_LIST_OF_USERS);
            generalStore.doActionGeneralError("Error accediendo al servidor: " + error.message);
        }
    });

    //*********************************************************
    doActionGetUserData = flow(function* (idUser) {
        try {
            const response = yield callServiceEventAware(Actions.GET_USER_DATA, getUserDataService, idUser);
            this.managerUser = response.data;
        } catch (error) {
            console.log("ERROR EN LLAMADA AL API EN usersStore.js - doActionGetUserData: " + error.message);
            console.log(error);
            generalStore.doActionEndService(Actions.GET_USER_DATA);
            generalStore.doActionGeneralError("Error accediendo al servidor: " + error.message);
        }
    })

    //*********************************************************
    doActionSaveUserEdit = flow(function* (data) {
        try {
            const response = yield callServiceEventAware(Actions.USER_SAVE_DATA, saveUserEditService, data);
            const user = response.data;
            let currentUsers = [];

            if (this.users.list == undefined) {
                this.users = { list: [user] }
            }
            else {
                currentUsers = [user, ...this.users.list.filter(item => item.id != user.id)];

                currentUsers.sort(this.sortUsers(this.usersOrderBy));

                this.message = "Los datos han sido guardados correctamente";
                this.users = { list: currentUsers };
            }
        }
        catch (error) {
            generalStore.doActionEndService(Actions.USER_SAVE_DATA);
            console.log("ERROR EN LLAMADA AL API EN usersStore.js - doActionSaveUserEdit: " + error.message);
            console.log(error);

            if (error.response != null) {
                if (error.response.status == 422) {
                    if (error.response.data != undefined && error.response.data.code != undefined )
                        generalStore.doActionGeneralError("Ya existe un usuario con ese email");
                    else
                        generalStore.doActionGeneralError("Error al guardar los datos");
                }
                else {
                    generalStore.doActionGeneralError("Error accediendo al servidor: " + error.message);
                }
            }
            else generalStore.doActionGeneralError("Se ha producido un error: " + error.message);
        }
    });

    //*********************************************************
    doActionSaveScore = flow(function* (data) {
        try {
            const response = yield callServiceEventAware(Actions.PLAYER_SAVE_SCORE, savePlayerScoreService, data);
            this.userLogged = response.data;
            this.message = "Los datos han sido guardados correctamente";
        }
        catch (error) {
            generalStore.doActionEndService(Actions.USER_SAVE_DATA);
            console.log("ERROR EN LLAMADA AL API EN usersStore.js - doActionSaveScore: " + error.message);
            console.log(error);

            if (error.response != null) {
                if (error.response.status == 422) {
                    if (error.response.data != undefined && error.response.data.code != undefined)
                        generalStore.doActionGeneralError("Ya existe un usuario con ese email");
                    else
                        generalStore.doActionGeneralError("Error al guardar los datos");
                }
                else {
                    generalStore.doActionGeneralError("Error accediendo al servidor: " + error.message);
                }
            }
            else generalStore.doActionGeneralError("Se ha producido un error: " + error.message);
        }
    })

    //*********************************************************
    doActionSavePlayerEdit = flow(function *(data){
        try {
            const response = yield callServiceEventAware(Actions.CHANGE_PLAYER_DATA, savePlayerEditService, data);
            this.userLogged = response.data;

        } catch (error) {
            generalStore.doActionEndService(Actions.CHANGE_PLAYER_DATA);
            console.log("ERROR EN LLAMADA AL API EN playersStore.js: " + error.message);
            console.log(error);

            if (error.response != null) {
                let code = error.response.data.code;
                let messageE = error.response.data.message;
                if (error.response.status == 422 && code == 401) {
                    
                    generalStore.doActionGeneralError("Error accediendo al servidor: " + messageE);
                }
                else {
                    generalStore.doActionGeneralError("Error accediendo al servidor: " + error.message);
                }
            }
            else generalStore.doActionGeneralError("Se ha producido un error: " + error.message);
        }
    });

    //*********************************************************
    doActionRemoveUser = flow(function* (data) {
        try {
            yield callServiceEventAware(Actions.USER_REMOVE, removeUserService, data);

            if (this.users.list != undefined) {
                let currentUsers = [...this.users.list.filter(item => item.id != data)];

                this.message = "Se ha eliminado correctamente el usuario"
                this.users = { list: currentUsers };

            }

        } catch (error) {
            generalStore.doActionEndService(Actions.USER_REMOVE);
            console.log("ERROR EN LLAMADA AL API EN usersStore.js - doActionRemoveUser: " + error.message);
            console.log(error);

            if (error.response != null) {
                if (error.response.status == 500) {
                    generalStore.doActionGeneralError("Error al eliminar el usuario");
                }
                else if (error.response.status == 409) {
                    generalStore.doActionGeneralError("Error al eliminar el usuario. Actualmente está en uso.");
                }
                else {
                    generalStore.doActionGeneralError("Error accediendo al servidor: " + error.message);
                }
            }
            else generalStore.doActionGeneralError("Se ha producido un error: " + error.message);
        }
    });

    //*********************************************************
    doActionChangeOrderBy = flow(function* (orderBy) {
        this.usersOrderBy = orderBy;
    });

    //*********************************************************
    sortUsers(sortBy) {
        return function (a, b) {

            let order = sortBy.split("_");

            /*if (order[0] == "role") {
                if (order[1].toUpperCase() == "ASC") {
                    return eval('b.' + order[0] + '.toString().localeCompare(a.' + order[0] + '.toString())');
                }
                else
                    return eval('a.' + order[0] + '.toString().localeCompare(b.' + order[0] + '.toString())');
            } 
            else {*/
            if (order[1].toUpperCase() == "ASC") {
                return eval('a.' + order[0] + '.toString().localeCompare(b.' + order[0] + '.toString())');
            } else
                return eval('b.' + order[0] + '.toString().localeCompare(a.' + order[0] + '.toString())');
        }
        //}
    }

     //*********************************************************
     doActionGetTeamPlayersByTeam = flow(function* (idTeam) {       
        try {
            const response = yield callServiceEventAware(Actions.GET_PAGINATED_LIST_OF_TEAM_PLAYERS, getTeamPlayersByTeam, idTeam);
            this.users = response.data;
        } catch (error) {
            console.log("ERROR EN LLAMADA AL API EN usersStore.js - doActionGetTeamPlayersByTeam: " + error.message);
            console.log(error);
            generalStore.doActionEndService(Actions.GET_PAGINATED_LIST_OF_TEAM_PLAYERS);
            generalStore.doActionGeneralError("Error accediendo al servidor: " + error.message);
        }
    })

    //***********************************************************
    updateUserInSession(updatedUser){        
        this.userLogged = updatedUser
    }
}

export default new usersStore()