import Phaser from "phaser";
/**
 * Clase para crear el Primer Nivel del juego
 * @author Mehdi Lahbis Mhamdi
 * @class
 * @property {Array} elements - Array con las preguntas y fotos de cada pantalla del nivel.
 * @property {number} score - Puntuación. 
 * @property {number} attempts - Número de intentos. 
 * @property {String} selectedImage - Imagen seleccionada.
 * @property {boolean} canMove - Para controlar la interacción del usuario/a.
 * @property {boolean} passed - Flag para saber si se pasó de nivel o no.
 */
export class FirstLevel extends Phaser.Scene {
    elements = [];

    canMove = false;

    busy = false;

    passed = false;

    attempts = 0;

    response = false;

    score = 0;

    selectedImage = '';

    gameOverFlag = false;

    numLevel = 1;

    gridConfiguration = {
        x: 620,
        y: 550,
        paddingX: 320,
        paddingY: 100
    };

    // *******************************************************************************
    constructor() {
        super({
            key: 'firstLevel'
        });
    }

    // *******************************************************************************
    init() {
        this.cameras.main.fadeIn(500);
        this.attempts = 3;
        this.elements = [
            {
                id: 1, question: "Llanura de inundación ocupada por construcciones",
                description: "El principal factor que provoca el riesgo de inundación es la ocupación de terrenos inundables por usos vulnerables: viviendas, industrias, infraestructuras, etc." + "\n" + "¿En cuál de estos ríos no se producirán daños en la siguiente inundación?",
                answers: ['image1-1', 'image1-2', 'image1-3', 'image1-4', 'image1-5', 'image1-6'],
                correctAnswer: 'image1-3',
                correctText: '¡Genial!' + "\n" + 'Las llanuras de inundación en estado natural nos protegen de los efectos negativos de las inundaciones porque, cuando  los caudales del río aumentan y el río se desborda, además de no producirse daños a las personas y los bienes en ese tramo, se reducen los daños en poblaciones situadas aguas abajo.' + "\n\n" + '¿Por qué? Porque, al estar cubiertas de vegetación, el suelo es permeable, parte del agua del río se infiltra en el suelo, y disminuye el caudal que lleva.'
            },
            {
                id: 2, question: "Río canalizado",
                description: "Uno de los problemas de conservación de nuestros ríos es haberlos canalizado para intentar que no ocupen la llanura de inundación en época de lluvias extraordinarias. ¿Me ayudas a encontrar el tramo canalizado?",
                answers: ['image2-1', 'image2-2', 'image2-3', 'image2-4', 'image2-5', 'image2-6'],
                correctAnswer: 'image2-2',
                correctText: '¡Muy bien!' + "\n" + 'Un río canalizado ya no se comporta como un ecosistema fluvial, por  lo que perdemos las importantes funciones que cumplen estos ecosistemas. Algunas ciudades están mejorando el estado de conservación de sus ríos, que estaban canalizados. Se les da más espacio y se plantan especies de vegetación de ribera propias de ese río, para intentar que recupere alguna de las funciones que tenía el ecosistema.'
            },
            {
                id: 3, question: "Río soterrado",
                description: "¿Sabías que en tu ciudad hay ríos que no ves? Para poder construir las ciudades hemos soterrado (enterrado) los ríos que las atraviesan. ¿Puedes encontrar el comienzo del río soterrado?",
                answers: ['image3-1', 'image3-2', 'image3-3', 'image3-4', 'image3-5', 'image3-6'],
                correctAnswer: 'image3-5',
                correctText: '¡Perfecto!' + "\n" + 'Un río soterrado ya no se comporta como un ecosistema fluvial, por  lo que perdemos las importantes funciones que cumplen estos ecosistemas. En algunos proyectos de restauración de ríos se llegan a eliminar los soterramientos para que el río vuelva a su cauce original.'
            },
            {
                id: 4, question: "Sobreexplotación de agua",
                description: "Los ríos y acuíferos nos proporcionan el agua que consumimos en nuestros hogares y la que consumimos en otros usos (en la agricultura y ganadería, las industrias, la producción de energía hidroeléctrica y en otras actividades económicas). Si extraemos demasiada agua, se produce la sobreexplotación de este recurso natural y su agotamiento. ¿Podrías localizar la imagen en la que está ocurriendo este problema?",
                answers: ['image1-4-1', 'image1-4-2', 'image1-4-3', 'image1-4-4', 'image1-4-5', 'image1-4-6'],
                correctAnswer: 'image1-4-6',
                correctText: '¡Perfecto!' + "\n" + 'Por eso es importante controlar el agua que se capta de los ríos y de los acuíferos y hacer un consumo responsable de ella. Además, muchas personas investigan para desarrollar tecnologías avanzadas que nos permitan reducir el volumen de agua que necesitamos para los diferentes usos.'
            }
        ];
        this.element = this.elements[0];
        this.textStyle = {
            fontFamily: 'Quicksand',
            fontSize: this.element.description.length < 300 ? '30px' : '26px',
            color: '#ffffff',
            align: 'center',
            wordWrap: { width: 700, useAdvancedWrap: true }  // Ancho máximo para el ajuste del texto
        };
    }

    // *******************************************************************************
    shuffleAnswers(array) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    }

    // *******************************************************************************
    create() {
        // background y titulo
        this.add.image(this.game.scale.width / 2, this.game.scale.height / 2, 'bg');

        const title = this.add.text(0, -1000, "Explora tu río", {
            align: 'center', fontSize: 45, fontFamily: "Quicksand"
        });

        title.x = this.game.scale.width / 2 - title.width / 2;

        this.add.tween({
            targets: title,
            ease: Phaser.Math.Easing.Expo.InOut,
            duration: 1000,
            y: 20
        });

        // icono puzzle y su animación (de derecha a izquierda)
        const puzzle = this.add.image(10000, 100, 'icon');
        puzzle.setScale(0.02);

        this.add.tween({
            targets: puzzle,
            ease: Phaser.Math.Easing.Expo.InOut,
            duration: 1000,
            x: this.scale.width - 100
        });

        // nombre de tanda y su animación (de izquierda a derecha)
        const series = this.add.text(-200, 50, 'NIVEL 1',
            { align: 'center', fontStyle: "bold", fontSize: 45, fontFamily: "Quicksand", color: '#F39C12' }).setDepth(1);

        this.add.tween({
            targets: series,
            ease: Phaser.Math.Easing.Expo.InOut,
            duration: 1000,
            x: 50
        });
        // fondo blanco para el nombre de la tanda
        this.add.image(90, 75, 'textBg').setTintFill(0xFFFFFF);
        this.add.image(140, 75, 'textBg').setTintFill(0xFFFFFF);
        this.add.image(190, 75, 'textBg').setTintFill(0xFFFFFF);

        // fondo blanco para la puntuación
        this.add.image(350, 75, 'textBg').setTintFill(0xFFFFFF);
        this.add.image(400, 75, 'textBg').setTintFill(0xFFFFFF);
        this.add.image(450, 75, 'textBg').setTintFill(0xFFFFFF);
        this.add.image(500, 75, 'textBg').setTintFill(0xFFFFFF);
        this.add.image(550, 75, 'textBg').setTintFill(0xFFFFFF);

        // puntuación y su animación
        this.scoreText = this.add.text(-500, 60,
            "PUNTUACIÓN|  " + this.score,
            { align: "top", fontStyle: "bold", fontSize: 30, fontFamily: 'Quicksand', color: "#F39C12" }
        ).setDepth(1);

        this.add.tween({
            targets: this.scoreText,
            ease: Phaser.Math.Easing.Expo.InOut,
            duration: 1000,
            delay: 500,
            x: 305
        });

        // botón y texto 'Jugar' 
        this.playContainer = this.add.container(1550, 200);

        this.playText = this.add.text(0, 0, 'COMPROBAR',
            { align: 'center', fontStyle: "bold", fontSize: 30, fontFamily: "Quicksand", color: '#ffffff' }).setDepth(1);
        this.playText.setOrigin(0.5, 0.5).setInteractive({ cursor: 'pointer' });

        const playBg = this.createBackground(this.playText, 0x2596be);

        this.playContainer.add([playBg, this.playText]);

        const play = this.add.image(1550, 300, 'nextbtn').setScale(0.1).setInteractive({ cursor: 'pointer' });

        this.playText.on(Phaser.Input.Events.POINTER_DOWN, () => {
            if (this.canMove) {
                console.log(this.playContainer);
                this.check();
            }
        })

        play.on(Phaser.Input.Events.POINTER_DOWN, () => {
            if (this.canMove) {
                console.log(this.playContainer);
                this.check();
            }
        });

        // creamos las gotas (vidas) y se las asignamos a la variable this.waterDrops
        this.waterDrops = this.createWaterDrops();

        this.attemptsForScreen = 3;

        this.elements.map(item => {
            this.shuffleAnswers(item.answers);
        });

        this.game.events.emit("POINTS", this.score);
        this.game.events.emit("PASSED LEVEL", this.passed);
        this.game.events.emit("PASSED NUM LEVEL", this.numLevel);

        // llamada al método que da comienzo al juego
        this.startGame();
    }

    // *******************************************************************************
    update() {
        this.scoreText.setText('PUNTUACIÓN |  ' + this.score);

        if (this.selectedImage == "" && this.elements.length < 0) {
            this.images.map(image => image.setAlpha(1));
        }

        if (this.selectedImage == '') {
            this.canMove = false;
        }

        if (this.attempts == 0) {
            this.canMove = false;
            this.gameOver();
            this.attempts -= 1;
        }

        if (this.passed) {
            this.game.passed = this.passed;
            this.nextLevel();
            this.passed = false;
        }

        this.game.score = this.score;
        
        this.game.numLevel = this.numLevel;
    }

    // *******************************************************************************
    createBackground(item, color) {
        const textWidth = item.width;
        const textHeight = item.height;

        // Crear el objeto gráfico para la pastilla de fondo
        const padding = 35; // Espacio adicional alrededor del texto
        const background = this.add.graphics();
        background.fillStyle(color, 0.9);

        // Dibujar un rectángulo redondeado como fondo
        background.fillRoundedRect(
            item.x - textWidth / 2 - padding / 2, // X posición (centrada)
            item.y - textHeight / 2 - padding / 2, // Y posición (centrada)
            textWidth + padding, // Ancho del rectángulo
            textHeight + padding, // Altura del rectángulo
            30 // Radio de las esquinas redondeadas
        );

        background.lineStyle(3, 0xffffff)

        background.strokeRoundedRect(
            item.x - textWidth / 2 - padding / 2, // posición x (centrada)
            item.y - textHeight / 2 - padding / 2, // posición y (centrada)
            textWidth + padding, // ancho (ajustado al grosor del borde)
            textHeight + padding, // alto (ajustado al grosor del borde)
            30
        );
        background.setDepth(-1);

        return background;
    }

    // *******************************************************************************
    createWaterDrops() {
        return Array.from(new Array(this.attempts)).map((el, index) => {
            const waterDrop = this.add.image(-1000, 980, 'drop')
                .setScale(0.5).setDepth(1);

            const waterDropBox = this.add.image(0, 980, 'textBg').setTintFill(0xFFFFFF).setScale(0.8);

            waterDropBox.x = (this.game.scale.width / 2 - waterDrop.width / 2) + 40 * index;

            this.add.tween({
                targets: [waterDrop],
                ease: Phaser.Math.Easing.Expo.InOut,
                duration: 1000,
                delay: 500 + index * 200,
                x: (this.game.scale.width / 2 - waterDrop.width / 2) + 40 * index, // marginLeft + spaceBetween * index
                onComplete: () => {
                    this.canMove = false;
                }
            });

            waterDrop.box = waterDropBox;

            return waterDrop;
        });
    }

    // *******************************************************************************
    createImageAnswers() {
        return this.element.answers?.map((name, index) => {
            const image = this.add.image(0,
                this.gridConfiguration.y + (150 + this.gridConfiguration.paddingY) * Math.floor(index / 3),
                name).setScale(0.7).setInteractive({ cursor: 'pointer' });

            image.x = (this.game.scale.width / 2 - image.width + this.gridConfiguration.paddingX / 2 - 15) + (98 + this.gridConfiguration.paddingX) * (index % 3);

            var maskGraphics = this.make.graphics({ x: 0, y: 0 });
            maskGraphics.fillStyle(0xffffff);  // El color es irrelevante, ya que es solo una máscara.
            maskGraphics.fillRoundedRect(0, 0, image.displayWidth, image.displayHeight, 30);  // 30 es el radio de redondeo

            // Crear la máscara de la forma dibujada y aplicarla a la imagen
            var mask = maskGraphics.createGeometryMask();
            image.setMask(mask);

            maskGraphics.setPosition(image.x - image.displayWidth / 2, image.y - image.displayHeight / 2);

            let borde = this.add.graphics();

            // Definir el estilo del borde (grosor, color, etc.)
            borde.lineStyle(5, 0xffffff, 1); // grosor de 5px, color rojo

            // Dibujar un rectángulo de borde alrededor de la imagen
            borde.strokeRoundedRect(
                image.x - image.displayWidth / 2, // posición x (centrada)
                image.y - image.displayHeight / 2, // posición y (centrada)
                image.displayWidth, // ancho (ajustado al grosor del borde)
                image.displayHeight, // alto (ajustado al grosor del borde)
                30
            );

            image.busy = this.busy;
            image.mask = mask;
            image.stroke = borde;

            return image;
        })
    }

    // *******************************************************************************
    restartGame() {
        this.cameras.main.fadeOut(100 * this.images.length);
        this.images = [];
        this.time.addEvent({
            delay: 100 * this.images.length,
            callback: () => {
                this.images = [];
                this.score = 0;
                this.canMove = false;
                this.scene.restart();
            }
        })
    }

    // *******************************************************************************
    startGame() {
        this.agent?.destroy();
        this.questionContainer?.destroy();
        this.images?.map(image => image?.destroy());
        this.selectedImage = '';
        this.element = this.elements[0];

        this.images = this.createImageAnswers();

        if (this.element.id % 2 != 0) {
            this.agent = this.add.image(470, 270, 'manAgent').setScale(0.4).setDepth(1).setAngle(-10);
        } else {
            this.agent = this.add.image(470, 280, 'womanAgent').setScale(0.4).setDepth(1).setAngle(-10);
        }

        this.questionContainer = this.add.container(0, 0);

        this.centerText = this.add.text(0, 0, this.element.description).setStyle(this.textStyle)
            .setOrigin(0.5, 0.5);

        // fondo palabra
        const textWidth = this.centerText.width;
        const textHeight = this.centerText.height;

        // Crear el objeto gráfico para la pastilla de fondo
        const padding = this.element.description.length < 300 ? 100 : 50; // Espacio adicional alrededor del texto
        const background = this.add.graphics();
        background.fillStyle(0xFF8000, 0.9);

        // Dibujar un rectángulo redondeado como fondo
        background.fillRoundedRect(
            this.centerText.x - textWidth / 2 - padding / 2, // X posición (centrada)
            this.centerText.y - textHeight / 2 - padding / 2, // Y posición (centrada)
            textWidth + padding, // Ancho del rectángulo
            textHeight + padding, // Altura del rectángulo
            70 // Radio de las esquinas redondeadas
        );

        background.lineStyle(10, 0xFFFFFF)

        background.strokeRoundedRect(
            this.centerText.x - textWidth / 2 - padding / 2, // X posición (centrada)
            this.centerText.y - textHeight / 2 - padding / 2, // Y posición (centrada)
            textWidth + padding, // Ancho del rectángulo
            textHeight + padding, // Altura del rectángulo
            70
        );

        // Asegurarse de que el fondo esté detrás del texto
        background.setDepth(-1);
        this.questionContainer.x = this.game.scale.width / 2 - this.questionContainer.width / 2;
        this.questionContainer.add([background, this.centerText]);
        this.questionContainer.y = 250;

        this.images.forEach(image => {
            image.on('pointerdown', () => {
                this.canMove = true;
                // if (this.canMove) {
                    this.images.map(image => {
                        image.setAlpha(1)
                        image.stroke.clear(); // Limpiar el borde anterior
                        image.stroke.lineStyle(5, 0xffffff, 1); // Establecer el nuevo color
                        image.stroke.strokeRoundedRect(
                            image.x - image.displayWidth / 2,
                            image.y - image.displayHeight / 2,
                            image.displayWidth,
                            image.displayHeight,
                            30
                        );
                    })
                    this.response = false;
                    image.busy = false;
                    if (!this.response) {
                        image.busy = true;
                        image.stroke.clear(); // Limpiar el borde anterior
                        image.stroke.lineStyle(5, 0xffff00, 1); // Establecer el nuevo color
                        image.stroke.strokeRoundedRect(
                            image.x - image.displayWidth / 2,
                            image.y - image.displayHeight / 2,
                            image.displayWidth,
                            image.displayHeight,
                            30
                        );
                        this.selectedImage = image;
                        console.log(this.selectedImage);

                        this.images.map(image => {
                            if (!image.busy) {
                                image.setAlpha(0.5)
                            }
                        })
                        this.response = true;
                        image.busy = false;
                    }
                //}
            })

        });

    }

    // *******************************************************************************
    check() {
        const correctAnswer = this.add.image(0, 0, 'ok')
            .setDepth(3)
            .setInteractive()
            .setScale(0.1);

        const close = this.add.image(0, 0, 'close')
            .setDepth(3)
            .setScale(0.2);

        this.correctAnswerContainer = this.add.container(0, 0);

        this.correctText = this.add.text(0, 0, this.element.correctText).setStyle(this.textStyle)
            .setOrigin(0.5, 0.5);

        // fondo palabra
        const textWidth = this.correctText.width;
        const textHeight = this.correctText.height;

        // Crear el objeto gráfico para la pastilla de fondo
        const padding = this.element.correctText.length < 300 ? 100 : 50; // Espacio adicional alrededor del texto
        const background = this.add.graphics();
        background.fillStyle(0x5fb05b, 1);

        // Dibujar un rectángulo redondeado como fondo
        background.fillRoundedRect(
            this.correctText.x - textWidth / 2 - padding / 2, // X posición (centrada)
            this.correctText.y - textHeight / 2 - padding / 2, // Y posición (centrada)
            textWidth + padding, // Ancho del rectángulo
            textHeight + padding + correctAnswer.displayHeight, // Altura del rectángulo
            70 // Radio de las esquinas redondeadas
        );

        background.lineStyle(10, 0xFFFFFF)

        background.strokeRoundedRect(
            this.correctText.x - textWidth / 2 - padding / 2, // X posición (centrada)
            this.correctText.y - textHeight / 2 - padding / 2, // Y posición (centrada)
            textWidth + padding, // Ancho del rectángulo
            textHeight + padding + correctAnswer.displayHeight, // Altura del rectángulo
            70
        );

        this.correctText.y += correctAnswer.displayHeight;

        // Asegurarse de que el fondo esté detrás del texto
        background.setDepth(-1);
        this.correctAnswerContainer.x = this.game.scale.width / 2 - this.correctAnswerContainer.width / 2;
        this.correctAnswerContainer.add([background, this.correctText, correctAnswer, close]);
        this.correctAnswerContainer.y = 500;
        this.correctAnswerContainer.setScale(0);
        correctAnswer.y = this.correctText.y - this.correctText.height / 2 - padding;
        correctAnswer.x = this.correctText.x - this.correctText.width / 2 + padding;

        close.x = this.correctText.x + this.correctText.width / 2 - padding;
        close.y = this.correctText.y - this.correctText.height / 2 - padding;

        close.setAlpha(0)

        const countdownText = this.add.text(0, 0, '5', { fontSize: '22px', fontFamily: 'Arial', color: '#ffffff' }).setScale(0);
        countdownText.x = textWidth * 2 - padding * 2 + 10;
        countdownText.y = textHeight * 2 - padding * 2 - 110;
    
        const wrongAnswer = this.add.image(this.selectedImage.x, this.selectedImage.y, 'x')
            .setDepth(3)
            .setInteractive()
            .setScale(0);

        // ********   ACIERTO   ***********
        if (this.selectedImage.texture?.key == this.element.correctAnswer) {
            this.canMove = false;

            var score = 0;

            if (this.attemptsForScreen == 3) {
                score = 10;
            } else if (this.attemptsForScreen == 2) {
                score = 7;
            } else {
                score = 5;
            }

            const scoretext = this.add.text(520, -20, '+' + score,
                { align: 'center', fontStyle: "bold", fontSize: 22, fontFamily: "Quicksand", color: '#fff' });

            // animación de la puntuación obtenida
            this.add.tween({
                targets: scoretext,
                x: 520,
                y: 80,
                duration: 1500,
                onUpdate: () => {
                    this.canMove = false;
                },
                onComplete: () => {
                    this.score += score;
                    scoretext.destroy();
                }
            });

            this.attemptsForScreen = 3

            this.add.tween({
                targets: this.correctAnswerContainer,
                scale: 1,
                duration: 1200,
                ease: Phaser.Math.Easing.In,
                onComplete: () => {
                    this.tweens.add({
                        targets: close,
                        alpha: 1,
                        duration: 5000, 
                        onComplete: () => {
                            close.setInteractive({ cursor: 'pointer' }); 
                        }
                    });

                    close.on('pointerdown', () => {
                        this.correctAnswerContainer?.destroy();
                        this.elements = this.elements.filter(item => item.id != this.element.id);

                        // si no quedan palabras en este nivel pasamos al siguiente
                        if (this.elements.length <= 0) {
                            this.passed = true;
                            this.numLevel = 2;
                            this.canMove = false;
                        } else {
                            this.canMove = false;
                            // si quedan palabras en el nivel volvemos a llamar al método que inicia el juego
                            if (this.elements.length > 0) {
                                setTimeout(() => {
                                    this.images.map(item => this.add.tween({
                                        targets: [item, this.centerText, this.agent],
                                        duration: 2000,
                                        //delay: 1000,
                                        scale: 0,
                                        onUpdate: () => {
                                            this.canMove = false;
                                        },
                                        onComplete: () => {
                                            this.canMove = true;
                                            this.startGame();
                                        }
                                    }));
                                }, 1000);
                            }
                        }
                    })
                }
            });
        }
        // ********   FALLO   ***********
        else {
            this.canMove = false;
            this.attemptsForScreen--;
            //this.cameras.main.setAlpha(0.9);
            const lastwaterDrop = this.waterDrops[this.waterDrops.length - 1];
            this.add.tween({
                targets: lastwaterDrop,
                duration: 1000,
                alpha: 0.2,
                onComplete: () => {
                    this.waterDrops.pop();
                }
            });
            this.add.tween({
                targets: wrongAnswer,
                scale: 0.2,
                duration: 1000,
                ease: Phaser.Math.Easing.In,
                onComplete: () => {
                    this.selectedImage.stroke.clear(); // Limpiar el borde anterior
                    this.selectedImage.stroke.lineStyle(5, 0xff0000, 1); // Establecer el nuevo color
                    this.selectedImage.stroke.strokeRoundedRect(
                        this.selectedImage.x - this.selectedImage.displayWidth / 2,
                        this.selectedImage.y - this.selectedImage.displayHeight / 2,
                        this.selectedImage.displayWidth,
                        this.selectedImage.displayHeight,
                        30
                    );
                },
            });

            setTimeout(() => {
                wrongAnswer.destroy();
                this.images.map(image => image.setAlpha(1));
                this.canMove = false;
                if (this.attempts > 0) {
                    this.selectedImage = "";
                    this.canMove = true;
                }
            }, 2000);

            // decrementar las oportunidades en 1 unidad
            this.attempts -= 1;
        }
    }

    // *******************************************************************************
    nextLevel() {
        const winnerContainer = this.add.container(0, 0);

        const nextLevel = this.add.image(1800, 900, 'next').setAlpha(0.5).setTintFill(0xFFFFFF);

        const nextBox1 = this.add.image(1790, 970, 'textBg').setTintFill(0xFFFFFF);
        const nextBox2 = this.add.image(1840, 970, 'textBg').setTintFill(0xFFFFFF);
        const nextBox3 = this.add.image(1890, 970, 'textBg').setTintFill(0xFFFFFF);

        const nextBtn = this.add.image(1840, 870, 'nextbtn').setScale(0.1).setInteractive({ cursor: 'pointer' });

        const nextLevelText = this.add.text(1755, 955, 'Siguiente nivel',
            { align: 'center', fontStyle: "bold", fontSize: 25, fontFamily: "Quicksand", color: '#F39C12' })
            .setDepth(1)
            .setInteractive({ cursor: 'pointer' });

        const winnerText = this.add.text(1840, 780, "¡Muy bien!",
            { align: "center", fontStyle: "bold", fontSize: 40, fontFamily: "Quicksand", color: "#fff" }
        ).setOrigin(.5)
        .setDepth(3);

        winnerContainer.add(nextLevel);
        winnerContainer.add(nextBtn);

        winnerContainer.add(winnerText);
        winnerContainer.add(nextBox1);
        winnerContainer.add(nextBox2);
        winnerContainer.add(nextBox3);
        winnerContainer.add(nextLevelText);

        winnerContainer.y = 1000;

        this.add.tween({
            targets: winnerContainer,
            duration: 1500,
            ease: Phaser.Math.Easing.Out,
            y: 0,
        });
        this.canMove = false;

        // al pulsar para acceder al siguiente nivel
        nextLevelText.on(Phaser.Input.Events.POINTER_DOWN, () => {
            this.nextLevelTweens(winnerContainer);
        });
        // mismo comportamiento que en el caso anterior
        nextBtn.on(Phaser.Input.Events.POINTER_DOWN, () => {
            this.nextLevelTweens(winnerContainer);
        });
    }

    // *******************************************************************************
    nextLevelTweens(winnerContainer) {
        this.images.map(item => this.add.tween({
            targets: [item, this.centerText, this.agent],
            duration: 2000,
            delay: 1000,
            scale: 0
        }));

        this.waterDrops.map(item => this.add.tween({
            targets: item,
            duration: 2000,
            delay: 1000,
            y: 1500,
        }));

        this.add.tween({
            targets: [winnerContainer],
            duration: 2000,
            ease: Phaser.Math.Easing.Bounce.Out,
            delay: 1000,
            y: 1000,
            // al completarse la animación iniciamos el siguiente nivel
            onComplete: () => {
                this.scene.start('secondLevel', { score: this.score });
            }
        })
    }

    // *******************************************************************************
    gameOver() {
        this.images.map(item => item.setAlpha(0.5))
        this.canMove = false;

        // contenedor con todos los elementos del cuadro de volver a intentarlo
        const gameOverContainer = this.add.container(0, 0);
        const restartLevel = this.add.image(1800, 900, 'next').setAlpha(0.5).setTintFill(0xFFFFFF);

        const restartBox1 = this.add.image(1785, 970, 'textBg').setTintFill(0xFFFFFF);
        const restartBox2 = this.add.image(1840, 970, 'textBg').setTintFill(0xFFFFFF);
        const restartBox3 = this.add.image(1895, 970, 'textBg').setTintFill(0xFFFFFF);

        const restartBtn = this.add.image(1845, 870, 'restart').setScale(0.1).setInteractive({ cursor: 'pointer' });

        const restartLevelText = this.add.text(1740, 960, 'Vuelve a intentarlo',
            { align: 'center', fontStyle: "bold", fontSize: 22, fontFamily: "Quicksand", color: '#F39C12' })
            .setDepth(1)
            .setInteractive({ cursor: 'pointer' })

        const restartText = this.add.text(1840, 780, "¡Ups!",
            { align: "center", fontStyle: "bold", fontSize: 40, fontFamily: "Quicksand", color: "#fff" }
        ).setOrigin(.5)
            .setDepth(3);

        gameOverContainer.add(restartLevel);
        gameOverContainer.add(restartBtn);

        gameOverContainer.add(restartText);
        gameOverContainer.add(restartBox1);
        gameOverContainer.add(restartBox2);
        gameOverContainer.add(restartBox3);
        gameOverContainer.add(restartLevelText);

        gameOverContainer.y = 1000;

        this.add.tween({
            targets: gameOverContainer,
            duration: 1500,
            ease: Phaser.Math.Easing.Out,
            y: 0
        });

        restartBtn.on(Phaser.Input.Events.POINTER_DOWN, () => {
            this.gameOverTweens();
        });
        // mismo comportamiento en caso de pulsar en el texto
        restartLevelText.on(Phaser.Input.Events.POINTER_DOWN, () => {
            this.gameOverTweens();
        });
    }

    // *******************************************************************************
    gameOverTweens(gameOverContainer) {
        this.images.map(item => this.add.tween({
            targets: [item],
            duration: 2000,
            delay: 1000 + 200 * item,
            y: 2000,
        }));

        this.add.tween({
            targets: [this.images, this.agent, this.questionContainer,
                gameOverContainer],
            duration: 2000,
            ease: Phaser.Math.Easing.Bounce.Out,
            y: 2000,
            onComplete: () => {
                this.restartGame();
            }
        })
    }
}