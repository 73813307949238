import React from 'react';
import './Avatar.scss'; // Archivo de estilos CSS para el avatar
import configApp from '../configApp.js';
import avatar from '../assets/images/Avatar_general.png';
import { withRouter } from 'react-router-dom';
import { inject, observer } from "mobx-react";
import GlobalFonts from "../assets/fonts/fonts";


class Avatar extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      bg: 'rgb(255, 165, 0)'
    }
  }

  render() {   
    const image = configApp.getParams().BACKEND_API_URL + "static/teams_avatar/" + this.props.team?.id + "_cropped.png";
    const team = this.props.team;
    const jsonConfig = team?.image_config;

    var bg = jsonConfig == undefined ? 'rgb(255, 165, 0)' : jsonConfig.split(',"')[0].split(":")[1].replace(/"/g, '');

    return (
      <>
        <GlobalFonts />
        <div className="avatar">
          <div className="image-avatar" style={{ backgroundColor: bg  }}><img className="avatar-image" src={this.props.team?.image_config == undefined ? avatar : image} /></div>

          <div className="nivel-equipo" style={{ backgroundColor: bg == 'rgb(245, 240, 240)' ? 'rgb(46, 46, 46)' : bg }}><p className="centered-text">NIVEL {this.props.levelTeam}</p></div>
          <br />
          <div className="nombre-equipo"><p className="centered-text">{this.props.nameTeam?.toUpperCase()} </p></div>
          <br />
          <div className="nombre-usuario"><p className="centered-text"> {this.props.userName?.toUpperCase()}</p></div>
          <br />
          <div className="puntos-equipo"><p className="centered-text">PUNTOS | {this.props.points}</p></div>
        </div>
      </>
    );
  }

}

export default withRouter(inject('usersStore', 'teamsStore')(observer(Avatar)));