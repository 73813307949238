import Phaser from "phaser";
import configApp from '../../../../configApp.js';

/**
 * Clase para cargar los elementos del juego
 * @author Mehdi Lahbis Mhamdi
 * @class
 */
export class Preloader extends Phaser.Scene {
    constructor() {
        super({
            key: 'Preloader'
        });
    }

    preload() {
        // fondo
        this.load.image('bg', configApp.getParams().PLAY_URL+'game/conexion_fluvial/assets/bg.png');
        // botón start
        this.load.image('startbtn', configApp.getParams().PLAY_URL+"game/conexion_fluvial/assets/btnStart.png");
        // icono puzzle 
        this.load.image('icon', configApp.getParams().PLAY_URL+"game/conexion_fluvial/assets/icono.png");
        // gota
        this.load.image('drop', configApp.getParams().PLAY_URL+"game/conexion_fluvial/assets/waterDrop.png");
        // textBg
        this.load.image('textBg', configApp.getParams().PLAY_URL+"game/conexion_fluvial/assets/textBg.png");
        // ok
        this.load.image('ok', configApp.getParams().PLAY_URL+"game/conexion_fluvial/assets/ok.png");
        // wrong answer
        this.load.image('x', configApp.getParams().PLAY_URL+"game/conexion_fluvial/assets/x.png");
        // cardinal point
        this.load.image('o', configApp.getParams().PLAY_URL+"game/conexion_fluvial/assets/zone.png");
        // cuadro y boton next
        this.load.image('checkBg', configApp.getParams().PLAY_URL+"game/conexion_fluvial/assets/checkBg.png");
        // icono volver a intentar
        this.load.image('restart', configApp.getParams().PLAY_URL+"game/conexion_fluvial/assets/tryAgainBtn.png");
        // cuadro y boton next
        this.load.image('nextbtn', configApp.getParams().PLAY_URL+"game/conexion_fluvial/assets/btnNext.png");
        this.load.image("next", configApp.getParams().PLAY_URL+"game/conexion_fluvial/assets/cuadroNext.png");
        // imagenes nivel 1
        // pantalla 1
        this.load.image("image1-1", configApp.getParams().PLAY_URL+"game/conexion_fluvial/assets/nivel1/pantalla1.png");
        this.load.image("image1-2", configApp.getParams().PLAY_URL+"game/conexion_fluvial/assets/nivel1/pantalla2.png");
        this.load.image("image1-3", configApp.getParams().PLAY_URL+"game/conexion_fluvial/assets/nivel1/pantalla3.png");
        this.load.image("image1-4", configApp.getParams().PLAY_URL+"game/conexion_fluvial/assets/nivel1/pantalla4.png");
        this.load.image("image1-5", configApp.getParams().PLAY_URL+"game/conexion_fluvial/assets/nivel1/pantalla5.png");
        this.load.image("image1-6", configApp.getParams().PLAY_URL+"game/conexion_fluvial/assets/nivel1/pantalla6.png");
        // pantalla 2
        this.load.image("image2-1", configApp.getParams().PLAY_URL+"game/conexion_fluvial/assets/nivel2/pantalla1.png");
        this.load.image("image2-2", configApp.getParams().PLAY_URL+"game/conexion_fluvial/assets/nivel2/pantalla2.png");
        this.load.image("image2-3", configApp.getParams().PLAY_URL+"game/conexion_fluvial/assets/nivel2/pantalla3.png");
        this.load.image("image2-4", configApp.getParams().PLAY_URL+"game/conexion_fluvial/assets/nivel2/pantalla4.png");
        this.load.image("image2-5", configApp.getParams().PLAY_URL+"game/conexion_fluvial/assets/nivel2/pantalla5.png");
        this.load.image("image2-6", configApp.getParams().PLAY_URL+"game/conexion_fluvial/assets/nivel2/pantalla6.png");
        // imagenes pantalla 4-nivel 2 (bienes publicos/privados)
        this.load.image("public-1", configApp.getParams().PLAY_URL+"game/conexion_fluvial/assets/nivel2/pantalla-especial/carretera.png");
        this.load.image("public-2", configApp.getParams().PLAY_URL+"game/conexion_fluvial/assets/nivel2/pantalla-especial/ciudad.png");
        this.load.image("public-3", configApp.getParams().PLAY_URL+"game/conexion_fluvial/assets/nivel2/pantalla-especial/playa.png");
        this.load.image("public-4", configApp.getParams().PLAY_URL+"game/conexion_fluvial/assets/nivel2/pantalla-especial/rio.png"); 
        this.load.image("private-1", configApp.getParams().PLAY_URL+"game/conexion_fluvial/assets/nivel2/pantalla-especial/coche.png");
        this.load.image("private-2", configApp.getParams().PLAY_URL+"game/conexion_fluvial/assets/nivel2/pantalla-especial/edificio.png");
        this.load.image("private-3", configApp.getParams().PLAY_URL+"game/conexion_fluvial/assets/nivel2/pantalla-especial/industria.png"); 
        // pantalla 3
        this.load.image("image3-1", configApp.getParams().PLAY_URL+"game/conexion_fluvial/assets/nivel3/pantalla1.png");
        this.load.image("image3-2", configApp.getParams().PLAY_URL+"game/conexion_fluvial/assets/nivel3/pantalla2.png");
        this.load.image("image3-3", configApp.getParams().PLAY_URL+"game/conexion_fluvial/assets/nivel3/pantalla3.png");
        this.load.image("image3-4", configApp.getParams().PLAY_URL+"game/conexion_fluvial/assets/nivel3/pantalla4.png");
        this.load.image("image3-5", configApp.getParams().PLAY_URL+"game/conexion_fluvial/assets/nivel3/pantalla5.png");
        this.load.image("image3-6", configApp.getParams().PLAY_URL+"game/conexion_fluvial/assets/nivel3/pantalla6.png");
        
    }

    /**
    * Método para dar inicio al juego después de cargar los elementos 
    */
    create() {
        this.scene.start("startScene");
    }
}