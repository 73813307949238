import Phaser from "phaser";
import configApp from '../../../../configApp.js';
export class Preloader extends Phaser.Scene
{
    /**
     * Constructor Preloader
     */
    constructor()
    {
        super({
            key: 'Preloader'
        });
    }

    preload ()
    {

        console.log("RUTA: " + configApp.getParams().PLAY_URL+'game/memorik/assets/fondo-verde.png');
        //Background
        this.load.image('bg',  configApp.getParams().PLAY_URL+'game/memorik/assets/fondo-verde.png').once('error', function (key, type, data) {
            console.log('ERROR al cargar la imagen', key, type, data);
        });   

        //score
        this.load.image('scoreBg', configApp.getParams().PLAY_URL+"game/memorik/assets/score_box.png");

        //puzzle icono
        this.load.image('icon', configApp.getParams().PLAY_URL+"game/memorik/assets/icono.png");

        //boton naranja comenzar
        this.load.image('startbtn', configApp.getParams().PLAY_URL+"game/memorik/assets/btnStart.png");

        //winner image
        this.load.image('ok', configApp.getParams().PLAY_URL+"game/memorik/assets/ok.png");

        // textBox
        this.load.image('textBg', configApp.getParams().PLAY_URL+"game/memorik/assets/fondoTexto (1).png");

        // gameOver
        this.load.image('gameOver', configApp.getParams().PLAY_URL+"game/memorik/assets/sigueIntentando.png");

        // level end
        this.load.image('levelEnd', configApp.getParams().PLAY_URL+"game/memorik/assets/cuadroFinNivel.png");

        // boton de volver a empezar
        this.load.image('restartBtn', configApp.getParams().PLAY_URL+"game/memorik/assets/tryAgainBtn.png");

        // elementos de Siguiente nivel
        this.load.image('next', configApp.getParams().PLAY_URL+"game/memorik/assets/cuadroFinNivel.png");
        this.load.image('nextBtn', configApp.getParams().PLAY_URL+"game/memorik/assets/nextBtn.png");
        
        //Nivel 1
        this.load.image("card-back", configApp.getParams().PLAY_URL+"game/memorik/assets/nivel1/reverso-nuevo.png");//reverso 200x300
        this.load.image("card-0", configApp.getParams().PLAY_URL+"game/memorik/assets/nivel1/pato-nuevo.png");//anade
        this.load.image("card-1", configApp.getParams().PLAY_URL+"game/memorik/assets/nivel1/rana-nuevo.png");//ranita
        this.load.image("card-2", configApp.getParams().PLAY_URL+"game/memorik/assets/nivel1/culebra-nuevo.png");//culebra
        this.load.image("card-3", configApp.getParams().PLAY_URL+"game/memorik/assets/nivel1/murcielago-nuevo.png");//murcielago
        this.load.image("card-4", configApp.getParams().PLAY_URL+"game/memorik/assets/nivel1/libelula-nuevo.png");//libelula
        this.load.image("card-5", configApp.getParams().PLAY_URL+"game/memorik/assets/nivel1/garza-nuevo.png");//garza
        //Nivel 2
        this.load.image("card-back2", configApp.getParams().PLAY_URL+"game/memorik/assets/nivel1/reverso-nuevo.png");// reverso 150x215
        this.load.image("card-6", configApp.getParams().PLAY_URL+"game/memorik/assets/nivel1/pato-nuevo.png");
        this.load.image("card-7", configApp.getParams().PLAY_URL+"game/memorik/assets/nivel2/avellano-nuevo.png");
        this.load.image("card-8", configApp.getParams().PLAY_URL+"game/memorik/assets/nivel1/culebra-nuevo.png");
        this.load.image("card-9", configApp.getParams().PLAY_URL+"game/memorik/assets/nivel1/garza-nuevo.png");
        this.load.image("card-10", configApp.getParams().PLAY_URL+"game/memorik/assets/nivel1/libelula-nuevo.png");
        this.load.image("card-11", configApp.getParams().PLAY_URL+"game/memorik/assets/nivel2/martin-nuevo.png");
        this.load.image("card-12", configApp.getParams().PLAY_URL+"game/memorik/assets/nivel1/murcielago-nuevo.png");
        this.load.image("card-13", configApp.getParams().PLAY_URL+"game/memorik/assets/nivel2/pijara-nuevo.png");
        this.load.image("card-14", configApp.getParams().PLAY_URL+"game/memorik/assets/nivel1/rana-nuevo.png");
        this.load.image("card-15", configApp.getParams().PLAY_URL+"game/memorik/assets/nivel2/salmon-nuevo.png");
        // Nivel 3 (invasoras)
        this.load.image("card-16", configApp.getParams().PLAY_URL+"game/memorik/assets/nivel3/blackbass-nuevo.png");
        this.load.image("card-17", configApp.getParams().PLAY_URL+"game/memorik/assets/nivel3/cala-nuevo.png");
        this.load.image("card-18", configApp.getParams().PLAY_URL+"game/memorik/assets/nivel3/cangrejoRojo-nuevo.png");
        this.load.image("card-19", configApp.getParams().PLAY_URL+"game/memorik/assets/nivel3/cangrejoSenhal-nuevo.png");
        this.load.image("card-20", configApp.getParams().PLAY_URL+"game/memorik/assets/nivel3/carpa-nuevo.png");
        this.load.image("card-21", configApp.getParams().PLAY_URL+"game/memorik/assets/nivel3/tortuga-nuevo.png");
        this.load.image("card-22", configApp.getParams().PLAY_URL+"game/memorik/assets/nivel3/gambusia-nuevo.png");
        this.load.image("card-23", configApp.getParams().PLAY_URL+"game/memorik/assets/nivel3/juncia-nuevo.png");
        this.load.image("card-24", configApp.getParams().PLAY_URL+"game/memorik/assets/nivel3/lilo-nuevo.png");
        this.load.image("card-25", configApp.getParams().PLAY_URL+"game/memorik/assets/nivel3/mimosa-nuevo.png");
        this.load.image("card-26", configApp.getParams().PLAY_URL+"game/memorik/assets/nivel3/poligono-nuevo.png");
        this.load.image("card-27", configApp.getParams().PLAY_URL+"game/memorik/assets/nivel3/senecio-nuevo.png");
        this.load.image("card-28", configApp.getParams().PLAY_URL+"game/memorik/assets/nivel3/tritonia-nuevo.png");
        this.load.image("card-29", configApp.getParams().PLAY_URL+"game/memorik/assets/nivel3/vison-nuevo.png");
        // Nivel 4 
        this.load.image("card-30", configApp.getParams().PLAY_URL+"game/memorik/assets/nivel4/aliso-nuevo.png");
        this.load.image("card-31", configApp.getParams().PLAY_URL+"game/memorik/assets/nivel4/arce-nuevo.png");
        this.load.image("card-32", configApp.getParams().PLAY_URL+"game/memorik/assets/nivel4/cormoran-nuevo.png");
        this.load.image("card-33", configApp.getParams().PLAY_URL+"game/memorik/assets/nivel4/lamprea-nuevo.png");
        this.load.image("card-34", configApp.getParams().PLAY_URL+"game/memorik/assets/nivel4/mirlo-nuevo.png");
        this.load.image("card-35", configApp.getParams().PLAY_URL+"game/memorik/assets/nivel4/salamandra-nuevo.png");
        // Nivel 5
        this.load.image("card-36", configApp.getParams().PLAY_URL+"game/memorik/assets/nivel5/anguila-nuevo.png");
        this.load.image("card-37", configApp.getParams().PLAY_URL+"game/memorik/assets/nivel5/colaDeCaballo-nuevo.png");
        this.load.image("card-38", configApp.getParams().PLAY_URL+"game/memorik/assets/nivel5/marabayo-nuevo.png");
        this.load.image("card-39", configApp.getParams().PLAY_URL+"game/memorik/assets/nivel5/triton-nuevo.png");
        // Nivel 6
        this.load.image("card-40", configApp.getParams().PLAY_URL+"game/memorik/assets/nivel6/turon-nuevo.png");
        this.load.image("card-41", configApp.getParams().PLAY_URL+"game/memorik/assets/nivel6/desman-nuevo.png");
        this.load.image("card-42", configApp.getParams().PLAY_URL+"game/memorik/assets/nivel6/fresno-nuevo.png");
        this.load.image("card-43", configApp.getParams().PLAY_URL+"game/memorik/assets/nivel6/mimbrera-nuevo.png");

        // Gota 
        this.load.image('drop', configApp.getParams().PLAY_URL+"game/memorik/assets/waterDrop.png");
        this.load.image('dropBg', configApp.getParams().PLAY_URL+"game/memorik/assets/drop_box.png");
    }

    create ()
    {
        this.scene.start("startScene");
    }
}