import React from "react";

import { Modal, Button, Row, Col } from "antd";
import { CloseCircleOutlined, BuildOutlined, BuildFilled, BuildTwoTone, StopOutlined, WarningFilled, WarningOutlined } from "@ant-design/icons";

//Para poder utilizar 'this.props.history.push()' en un componente
import { withRouter } from 'react-router-dom';
import { inject, observer } from "mobx-react";

import memorik from '../assets/images/miniaturaMemorik.png';
import palabra from '../assets/images/miniaturaPalabrasRevuelltas.png';
import rana from '../assets/images/miniaturaAtrapaLaMosca1.png';
import explora from '../assets/images/miniaturaExploraTuRio.png';
import conexion from '../assets/images/miniaturaConexionFluvial.png';

import icon from '../assets/images/buttons/minijuegos.png';

import GlobalFonts from "../assets/fonts/fonts";

import './PlaygroundModal.scss';
import { reaction } from "mobx";

class PlaygroundModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            title: '',
            icon: null,
        };

        this.reactions = [];
    }

    //*********************************************************
    componentDidMount() {
        this.handleLoadLevels();

        this.reactions.push(
            reaction(
                () => this.props.show,
                () => {
                    if (this.props.show == true) {
                        this.setState({ show: this.props.show })
                    }
                }
            )
        )
    }

    //*********************************************************
    handleLoadLevels() {
        this.props.teamsStore.doActionGetLevelsList();
    }

    //*********************************************************
    accessToMemorik() {
        this.props.history.push('/memorik');
    }

    //*********************************************************
    accessToPalabraReuelta() {
        this.props.history.push('/palabrarevuelta');
    }

    //*********************************************************
    accessToAtrapaLaMosca() {
        this.props.history.push('/atrapa-la-mosca');
    }

    //*********************************************************
    accessToExploraTuRio() {
        this.props.history.push('/explora-tu-rio');
    }

    //*********************************************************
    accessToConexionFluvial() {
        this.props.history.push('/conexion-fluvial');
    }

    //*********************************************************
    render() {
        const level = this.props.team == undefined ? 0 : this.props.team.step.level.num;
        console.log("LEVEL: ", level)

        return (
            <>
                <GlobalFonts />
                <Modal
                    className="playgroundmodal"
                    title={
                        <Row justify='center' align="top">
                            <Col id="icon" sm={{ span: 4 }} md={{ span: 4 }}>
                                {<img className="modal-icon" src={icon} />}
                            </Col>
                            <Col className="title-background" sm={{ span: 16 }} md={{ span: 16 }}>
                                <div className="title">
                                    {this.props.title.toUpperCase()}
                                </div>
                            </Col>
                        </Row>
                    }
                    closeIcon=
                    {
                        <Button onClick={this.props.close} shape="circle" id="close-button" >
                            <CloseCircleOutlined className="close-circle" />
                        </Button>
                    }
                    open={this.props.show}
                    width="max-content"
                    bodyStyle={{ overflowY: 'scroll' }}
                    closable={true}
                    maskClosable={true}
                    footer={null}
                >
                    <div>

                        <div style={{ backgroundColor: '#0e3e41', textAlign: 'center' }}>
                            <Row justify="center" align="top">
                                <Col id="icon" xs={{ span: 8 }} sm={{ span: 8 }} md={{ span: 12 }}>
                                    <img src={memorik} className={level == 1 ? "games-image" : "games-image-bloqued"} alt="Memorik"
                                        onClick={() => level == 1 && this.accessToMemorik(this.props.user)} />
                                    {/* {level != 1 && <StopOutlined className="building-icon-outlined" />} */}
                                    <p className="games-title"> Memorik </p>
                                </Col>
                                <Col id="icon" xs={{ span: 8 }} sm={{ span: 8 }} md={{ span: 12 }}>
                                    <img src={palabra} className={level == 1 ? "games-image" : "games-image-bloqued"} alt="Palabra Revuelta"
                                        onClick={() => level == 1 && this.accessToPalabraReuelta(this.props.user)} />
                                    {/* {level != 1 && <StopOutlined className="building-icon-outlined" />} */}
                                    <p className="games-title"> La palabra revuelta </p>
                                </Col>
                            </Row>

                            <Row justify="center" align="top">
                                <Col id="icon" xs={{ span: 8 }} sm={{ span: 8 }} md={{ span: 12 }}>
                                    <img src={rana} className={level == 2 ? "games-image" : "games-image-bloqued"} alt="Atrapa la mosca"
                                        onClick={() => level == 2 && this.accessToAtrapaLaMosca(this.props.user)} />
                                    {/* {level < 2 && <> <StopOutlined className="building-icon-outlined" /> <p className="level-needed"> NIVEL 2 </p> </>} */}
                                    <p className="games-title"> Atrapa la mosca </p>
                                </Col>
                                <Col id="icon" xs={{ span: 8 }} sm={{ span: 8 }} md={{ span: 12 }}>
                                    <img src={explora} className={level == 2 ? "games-image" : "games-image-bloqued"} alt="Explora tu río"
                                        onClick={() => level == 2 && this.accessToExploraTuRio(this.props.user)} />
                                    {/* {level < 2 && <> <StopOutlined className="building-icon-outlined" /> <p className="level-needed"> NIVEL 2 </p> </>} */}
                                    <p className="games-title"> Explora tu río </p>
                                </Col>
                            </Row>

                            <Row justify="center" align="top">
                                <Col id="icon" xs={{ span: 8 }} sm={{ span: 8 }} md={{ span: 12 }}>
                                    <img src={conexion} className={level == 3 ? "games-image" : "games-image-bloqued"} alt="Conexión fluvial"
                                        onClick={() => level == 3 && this.accessToConexionFluvial(this.props.user)} />
                                    {/* // onClick={() => this.accessToConexionFluvial(this.props.user)}  */}
                                    {/* {level == 3 && <> <StopOutlined className="building-icon-outlined" /> <p className="level-needed"> NIVEL 3 </p> </>} */}
                                    <p className="games-title"> Conexión fluvial </p>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Modal>
            </>
        )
    }
}

export default withRouter(inject('teamsStore')(observer(PlaygroundModal)));